<template>
  <div class="orderDetailDialog">
    <el-dialog title="订单详情" :visible.sync="dialogVisible" width="968.93px" @close="repeateSend=false">
      <div class="flexbox">
        <div class="border-card order-detail-info">
          <div class="detail-info-main">
            <div class="order-li">订单号：{{ result.orderNumber }}</div>
            <div class="order-li">
              下单时间：{{ $formatDate(result.createTime, "yyyy-MM-dd HH:mm") }}
            </div>
            <div class="order-li">收货联系人：{{ result.contactName }}</div>
            <div class="order-li">收货电话：{{ result.contactTel }}</div>
            <div class="order-li">收货地址：{{ result.address }}</div>
          </div>
          <div class="remarks" v-if="result.description">
            备注:{{ result.description }}
          </div>
        </div>
        <div class="border-card flex_1 steps-main-box">
          <div class="dialy-number" v-if="result.dailyNumber">#{{ result.dailyNumber }}</div>
          <div class="img-box flexbox">
            <div class="img-item flexbox flex-tb">
              <span>到店照片</span>
              <img :src="result.takeImgUrl" alt="" />
            </div>
            <div class="img-item flexbox flex-tb">
              <span>送达照片</span>
              <img :src="result.arriveImgUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="mt15 flexbox" v-show="false">
        <!-- <p class="fontb">商品信息</p> -->
        <div style="width: 593px">
          <el-table :header-cell-style="{ borderColor: 'transparent' }" :cell-style="{ borderColor: 'transparent' }" stripe
            :data="result.orderItem" border height="430" style="border-radius: 6px">
            <!-- update col -->
            <el-table-column prop="goodsImg" label="商品图" align="center">
              <template slot-scope="scope">
                <img :src="
                    $imgPrefix(scope.row.goodsImg, '/img/common/none-img.png')
                  " class="shop-img" />
              </template>
            </el-table-column>
            <el-table-column prop="goodsName" label="商品名称" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.goodsName }}</span>
                <!-- &nbsp;&nbsp;
                <span v-if="scope.row.note" style="color: #999"
                  >({{ scope.row.goodsSpec }})</span
                >
                <span style="color: #999"
                  >&nbsp;&nbsp;
                  {{ !scope.row.is_discount ? "" : "(不参与优惠)" }}
                </span> -->
                <!-- （5.25折限享一份） -->
              </template>
            </el-table-column>
            <el-table-column prop="goodsCount" label="购买数量" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.goodsCount }}份</span>
              </template>
            </el-table-column>
            <el-table-column prop="goodsPrice" label="商品单价" align="center">
              <template slot-scope="scope">
                <span>￥{{ scope.row.goodsPrice }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="border-card finish-info">
          <div class="finish-part">
            <div class="bussiness-info">
              <div class="orderState">
                <!-- <span class="order" v-if="result.openDeliverState == 1">待接单</span>
                <span class="order" v-else-if="result.openDeliverState == 2">配送中</span>
                <span class="order" v-else-if="result.openDeliverState == 3">已完成</span>
                <span class="order" v-else-if="result.openDeliverState == 4">已撤销</span>
                <span class="order" v-else-if="result.openDeliverState == 5">异常</span>
                <span class="order" v-else-if="result.openDeliverState == 6">取餐中</span> -->
                <span class="order">{{getOrderStateShowDesc(result)}}</span>
                <span class="refund" v-if="formateRefundState(result)">({{formateRefundState(result)}})</span>
                <!-- <span class="refund" v-if="result.refundStatus == 1">(全部退款)</span>
                <span class="refund" v-else-if="result.refundStatus == 2">(部分退款)</span>
                <span class="refund" v-else-if="result.refundStatus == 3">(未退款)</span> -->
              </div>
              <div class="bussiness-item bussiness-name">{{result.merchantName}}</div>
              <div class="bussiness-item">{{result.merchantTel}}</div>
              <div class="bussiness-item">
                {{result.merchantAddress}}
              </div>
            </div>
            <div class="order-li">商品金额：¥{{ result.originalPrice }}</div>
            <div class="order-li">打包费用：¥{{ result.packageCost }}</div>
            <div class="order-li">
              配送费用：¥{{ result.distributionCost }}
              <span class="tips" v-if="result.distributionPlatform">({{ result.distributionPlatform }})</span>
            </div>
            <div class="order-li">订单金额：¥{{ result.orderPrice }}</div>
            <div class="order-li flexbox" v-if="result.orderPrice || result.giftDesc">
              营销活动：
              <div>
                <div v-if="result.orderPrice">
                  优惠¥{{ result.marketingActivity }}
                </div>
                <div v-if="result.giftDesc">{{ result.giftDesc }}</div>
              </div>
            </div>
            <div class="order-li flexbox flex-align-center flex-justify-between">
              实付金额：¥{{
                result.discountsCost > 0 ? result.discountsCost : 0
              }}
            </div>
            <div class="order-li">支付手续费：¥{{ result.shopBearFee }}</div>
            <div class="order-li">
              平台抽佣：¥{{
                result.spreadCommission > 0 ? result.spreadCommission : 0
              }}
            </div>
            <div class="order-li theme-color">
              退款金额：¥{{
                result.refundStatus == 1
                  ? result.discountsCost
                  : result.partialRefundMoney
              }}
            </div>
            <div class="order-li flexbox flex-align-center flex-justify-between">
              <div>
                <span class="real-income">实得收益：¥{{ result.orderFinance }}</span>
                <span class="income-state" v-if="result.accountState == 1">已结算</span>
                <span class="income-state" v-else-if="result.accountState == 2">待结算24小时</span>
                <span class="income-state" v-else-if="result.accountState == 3">待结算48小时</span>
                <span class="income-state" v-else-if="result.accountState == 4">待结算-立即入账</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer"></div>
      <template v-if="result.riderInfoList&&result.riderInfoList.length">
        <h5>配送信息：</h5>
        <div class="line-split-dom"></div>
        <div class="send-to-detail">
          <ul class="turn-send-list">
            <li v-for='(item,index) in result.riderInfoList' :key='index' class="send-item">
              <div>
                <div class="send-log" v-if="item.state==10">
                  <div class="send-log-text">转派订单</div>
                </div>
                <h4>{{item.riderName}} {{item.riderPhone}}</h4>
                <p><span class="process-label">接单</span> <span>{{item.receiveTime}}</span></p>
                <p><span class="process-label">到达商家位置</span> <span>{{item.takeTime}}</span></p>
                <p><span class="process-label">开始配送</span> <span>{{item.distributionTime}}</span></p>
                <p><span class="process-label">到达送货地址</span> <span>{{item.arriveTime}}</span></p>
              </div>

            </li>

          </ul>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { $sg_getThridOrderDetail } from '@/api/delivery';

export default {
  data() {
    return {
      dialogVisible: false,
      result: {},
      repeateSend: false,
    };
  },
  created() { },
  methods: {
    // 打开 弹窗
    open(skOrderId, orderState) {
      this.dialogVisible = true;
      const params = {
        skOrderId,
        orderState,
      };
      $sg_getThridOrderDetail(params).then((res) => {
        this.result = res;
        if (this.result.riderInfoList) {
          this.result.riderInfoList.forEach((item) => {
            // eslint-disable-next-line eqeqeq
            if (item.state == 10) {
              console.log('nimama', item.state);
              this.repeateSend = true;
            }
          });
        }
      });
    },
    formateRefundState({ userRefundState: state, orderState }) {
      const userRefundState = {
        1: '撤销中',
        2: '已拒绝退款',
        3: '已退款',
        5: '已撤销',
      };
      if (orderState === 5) {
        return false;
      } else if (userRefundState[state]) {
        return userRefundState[state];
      } else {
        return false;
      }
    },
    // 订单状态
    getOrderStateShowDesc({
      orderState, userRefundState, exceptionState, openDeliverState, distributionType,
    }) { // mealState,
      if (userRefundState === 1) return '退款中';
      if (orderState === 5) return '已撤销';
      // if (mealState === 0) return '待出餐';
      if (exceptionState === 1) return '无骑手接单';
      if (orderState === 3 && distributionType === 1 && openDeliverState === 1) return '骑手待接单';
      const stateConfig = ['', '待接单', '骑手待接单', '待收货', '已完成', '已撤销'];
      return stateConfig[orderState];
    },
  },
};
</script>
<style lang="scss">
@import "@/style/var.scss";
.orderDetailDialog {
  .el-dialog {
    border-radius: 6px;
    .el-dialog__header {
      padding: 10px 35px;
      border-bottom: 1px solid #ececec;
      background: #f8f7f7;
      border-radius: 6px 6px 0 0;
      .el-dialog__title {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        color: #333333;
      }
      .el-dialog__headerbtn {
        top: 14px;
        right: 20px;
      }
    }
    .el-dialog__body {
      padding: 18px 35px 28px;
    }
    .el-dialog__footer {
      text-align: center;
      padding: 0;
      .btn-main {
        padding: 14px 0 24px;
        .el-button {
          min-width: 110px;
          height: 32px;
          &.el-button--default {
            border: 1px solid #ececec;
            color: #999999;
          }
          + .el-button {
            margin-left: 20px;
          }
        }
      }
      &:after {
        display: block;
        content: "";
        height: 20px;
        border-top: 1px solid #ececec;
        background: #f8f7f7;
        border-radius: 0 0 6px 6px;
      }
    }
  }
  .steps-main-box {
    padding: 18px 44px;
    box-sizing: border-box;
    .dialy-number {
      font-size: 36px;
      font-weight: bold;
      line-height: 50px;
      color: $--theme-color;
    }
    .img-box {
      margin-top: 8px;
      .img-item {
        span {
          font-size: 12px;
          color: #666666;
        }
        img {
          width: 172px;
          height: 98px;
          margin-top: 8px;
        }
      }
      & > .img-item:first-child {
        margin-right: 132px;
      }
    }
  }
  .shop-img {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    margin: 5px 0;
  }
  .finish-info {
    width: 282.25px;
    margin-left: 20px;
    box-sizing: border-box;
    height: 430px;
    overflow-y: auto;
    .finish-part {
      padding: 20px 14px 20px 20px;
      & + .finish-part {
        border-top: 1px dashed #e4e4e4;
      }
      .bussiness-info {
        padding-bottom: 18px;
        margin-bottom: 20px;
        box-sizing: border-box;
        border-bottom: 1px dashed #f0f0f0;
        .orderState {
          color: $--theme-color;
          .order {
            font-size: 20px;
            font-weight: bold;
          }
          .refund {
            font-size: 14px;
            margin-left: 12px;
          }
        }
        .bussiness-item {
          font-size: 12px;
          color: #666;
          margin-top: 8px;
        }
        .bussiness-name {
          margin-top: 13px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
  .order-detail-info {
    padding: 24px 12px 14px;
    width: 299px;
    margin-right: 13px;
    min-height: 239.41px;
    box-sizing: border-box;
    .detail-info-main {
      padding: 0 10px;
    }
    .remarks {
      background: #f5f5f5;
      border-radius: 5px;
      width: 100%;
      padding: 9px 13px;
      box-sizing: border-box;
      margin-top: 13px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #666666;
    }
  }
  .border-card {
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 6px;
    .order-li {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #666666;
      + .order-li {
        margin-top: 8px;
      }
      .tips {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #999999;
        margin-left: 14px;
      }
      .real-income {
        color: $--theme-color;
        font-size: 14px;
        line-height: 20px;
      }
      .income-state {
        color: #999999;
        font-size: 12px;
        margin-left: 6px;
      }
    }
  }
  .line-split-dom {
    height: 1px;
    background: #ececec;
  }
  .send-to-detail {
    // position: relative;
    min-height: 100px;
    .send-item{
      position: relative;
 .send-log {
      position: absolute;
      top: 20px;
      right: 10px;
      border: 22px solid;
      height: 0px;
      width: 75px;
      border-color: red red red white;
      border-radius: 0px 10px 10px 0px;
      .send-log-text {
        color: white;
        margin: -17px 0px 0px 0px;
        padding-left: 18px;
      }
    }
    }
   
    .turn-send-list {
      max-height: 300px;
      overflow-y: auto;
      li {
        line-height: 35px;
      }
      .process-label {
        display: inline-block;
        width: 110px;
      }
    }
  }
}
</style>
