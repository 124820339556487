<template>
  <div class="distribution">
    <infoListBorder title="骑手收益设置" :required="1">
      <p>按比例计算骑手收益<span class="colorRed">(根据每单总配送费设置骑手收益比例)</span></p>
      <div class="fc mt6">
        每单骑手从配送费当中抽取
        <el-input size='small' class="inputInfo" type="number" clearable
          v-model.number="earningsConfigList[0].configCondList[0].earningsScale">
        </el-input><span class="mr5">%</span> 抽佣比例不能大于100%
      </div>
    </infoListBorder>
    <infoListBorder title="额外奖励骑手配送费" scendTitle="奖励为累计添加,从代理商账户余额扣除">
      <div class="fc mt6" v-for="(item,index) in earningsConfigList[1].configCondList" :key="index">
        每日完成配送
        <el-input size='small' class="inputInfo" type="number" clearable v-model.number="item.scope"></el-input>单，奖励
        <el-input size='small' class="inputInfo" type="number" clearable v-model.number="item.earningsPrice"></el-input>元
        <el-button type='text' class="detelBtn" icon="el-icon-delete" @click="delDataList(index)">删除</el-button>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addReward">新增奖励设置</el-button>
    </infoListBorder>
    <infoListBorder title="骑手罚款机制" scendTitle="骑手每天最多允许放弃3单" :required="1">
      <p class="fc mb10" v-for="(item,index) in earningsConfigList[2].configCondList" :key="index">
        <span class="fine-list" v-if="index<3">当日第{{times[item.scope]}}次放单，扣除当前放单订单收益金额的</span>
        <el-input v-if="index<3" size='small' class="inputInfo" type="number" clearable v-model.number="item.earningsScale"></el-input>
        <span v-if="index<3" class="mr5">%</span>
      </p>
    </infoListBorder>
    <div class="bottom-btn-group">
      <PrevReclick>
        <el-button size='small' @click="submitInfo(1)" type='primary' plain :loading="isSubmit" :disabled="isSubmit"
          v-if="$parent.rootAgentType">返回上一页</el-button>
      </PrevReclick>
      <el-button size='small' @click="submitInfo(3)" type='primary' :loading="isSubmit" :disabled="isSubmit">下一步，设置订单推送</el-button>
    </div>
  </div>
</template>

<script>
import { deepClone } from '@base/utils';
// import { $sp_psConfigAdd, $sp_psConfigUpdate } from '@/api/setting';
import infoListBorder from './infoListBorder.vue';

export default {
  components: {
    infoListBorder,
  },
  data() {
    return {
      isSubmit: false,
      times: ['', '一', '二', '三'],
      // 骑手收益
      earningsConfigList: [],
    };
  },
  created() {
    const { earningsConfigList } = this.$parent.fromSettinInfo.earningsConfig;
    this.earningsConfigList = deepClone(earningsConfigList);
  },
  methods: {
    // 新增奖励机制
    addReward() {
      const len = this.earningsConfigList[1].configCondList.length;
      if (len && this.validationReward()) {
        return;
      }
      this.earningsConfigList[1].configCondList.push({
        scope: null,
        earningsPrice: null,
      });
    },
    // 删除奖励机制
    delDataList(index) {
      this.$confirm('此操作将删除该条设置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.earningsConfigList[1].configCondList.splice(index, 1);
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
      });
    },
    // 验证奖励机制
    validationReward() {
      let errorInfo = '';
      const REG_NUMBER = /^([1-9][0-9]{0,3})$/;
      const REG_PRICE = /^(\d{1,4}(\.[0-9]{1})?)$|^9999$/;
      const dataArray = this.earningsConfigList[1].configCondList;
      if (dataArray.length) {
        for (let i = 0; i < dataArray.length; i += 1) {
          const item = dataArray[i];
          if (!REG_NUMBER.test(item.scope)) {
            errorInfo = '请正确输入配送单量，1~9999正整数';
            break;
          }
          if (i > 0 && Number(item.scope) <= Number(dataArray[i - 1].scope)) {
            errorInfo = '配置阶段单量需大于前一个配置阶段单量';
            break;
          }
          if (!REG_PRICE.test(item.earningsPrice)) {
            errorInfo = '请正确输入奖励金额，0~9999(可保留一位小数)';
            break;
          }
        }
      }
      if (errorInfo) {
        this.$message.error(errorInfo);
      }
      return errorInfo;
    },
    // 提交信息 进入下一环节
    submitInfo(val) {
      const REG_SCALE = /^(\d{1,2}(\.[0-9]{1})?)$|^100$/;
      if (!REG_SCALE.test(parseFloat(this.earningsConfigList[0].configCondList[0].earningsScale))) {
        this.$message.error('请正确输入骑手收益比例，0~100(可保留一位小数)');
        return;
      }
      if (this.earningsConfigList[1].configCondList.length && this.validationReward()) {
        return;
      }
      for (let j = 0; j < this.earningsConfigList[2].configCondList.length; j += 1) {
        const itemc = this.earningsConfigList[2].configCondList[j];
        if (!REG_SCALE.test(itemc.earningsScale)) {
          this.$message.error('请正确输入骑手罚款比例，0~100(可保留一位小数)');
          return;
        }
      }
      if (this.earningsConfigList[1].configCondList.length) {
        this.earningsConfigList[1].configCondList.forEach((item, index) => {
          item.serial = index + 1;
        });
      }
      const earnData = deepClone(this.earningsConfigList);
      if (!earnData[1].configCondList.length) {
        earnData.splice(1, 1);
      }
      this.$parent.fromSettinInfo.earningsConfig.earningsConfigList = earnData;
      this.$parent.setNextPage(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.detelBtn {
  margin-left: 30px;
}
.fc {
  display: flex;
  align-items: center;
}
.mb10 {
  margin-bottom: 10px;
}
.mt6 {
  margin-top: 6px;
}
.bottom-btn-group {
  max-width: 1200px;
  margin-top: 80px;
  margin-left: 30px;
}
.addTimer {
  margin-top: 20px;
}
.fine-list {
  display: inline-block;
  width: 320px;
}
.colorRed {
  color: #ef3f46;
}
.inputInfo {
  width: 130px;
  margin: 0 10px;
}
</style>
