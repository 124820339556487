<template>
  <div class="orderDispatch">
    <!-- 搜索框区域 -->
    <div class="search-box">
      <div class="search flexbox flex-align-center">
        <div class="mr60 mt20">
          <span class="name">订单类型</span>
          <el-select v-model="form.orderSubType" clearable class="inp">
            <el-option v-for="item in orderType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="mr60 mt20">
          <span class="name">配送状态</span>
          <el-select v-model="form.orderState" clearable class="inp">
            <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="mr60 mt20">
          <span class="name">骑手</span>
          <el-input v-model="form.riderNameOrRiderPhone" class="inp" clearable placeholder="骑手姓名/手机号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">流水号</span>
          <el-input v-model="form.orderNo" class="inp" clearable placeholder="流水号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">商户</span>
          <el-input v-model="form.merchantName" class="inp" clearable placeholder="商户"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">是否异常</span>
          <el-select v-model="form.exception" clearable class="inp">
            <el-option v-for="item in orderOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="mr60 mt20">
          <span class="name">收件人</span>
          <el-input v-model="form.acceptNameOrAcceptPhone" class="inp" clearable placeholder="名称/联系方式"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">订单来源</span>
          <el-select v-model="form.orderSource" clearable class="inp">
            <el-option v-for="(item, index) in orderSourceOptions" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="mr60 mt20">
          <span class="name">单号搜索</span>
          <el-input v-model="form.orderNumber" style="width: 200px;" class="inp" clearable placeholder="输入订单/配送单号/票号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">收发地址</span>
          <el-input v-model="form.acceptOrMerchantAddress" class="inp" clearable placeholder="输入地址信息"></el-input>
        </div>
      </div>
      <div class="flexbox flex-justify-between">
        <div class="flexbox flex-align-center flex-wrap">
          <div class="mr60 mt26">
            <span class="name">订单日期</span>
            <span :class="mode == '1' ? 'mode mode-active' : 'mode'" @click="selectMode('1')">今日</span>
            <span :class="mode == '2' ? 'mode mode-active' : 'mode'" @click="selectMode('2')">昨日</span>
            <span :class="mode == '3' ? 'mode mode-active' : 'mode'" @click="selectMode('3')">近7日</span>
            <span :class="mode == '4' ? 'mode mode-active' : 'mode'" @click="selectMode('4')">近30日</span>
          </div>
          <el-date-picker v-model="time" class="time mt26" type="daterange" range-separator="至" value-format="yyyy-MM-dd"
            start-placeholder="选择开始日期" end-placeholder="选择结束日期" :clearable="false" :picker-options="dateRange" @change="timeChange">
          </el-date-picker>
          <el-button type="primary" class="btn search-btn" @click="search($event)">筛选</el-button>
          <el-button type="info" class="btn clear-btn" @click="clear">清空</el-button>
          <el-button type="primary" style="margin-top: 26px;" @click="queryRiderRedeployByAgent">转派记录</el-button>
        </div>
        <img src="/img/delivery/refresh.png" alt="" class="refreshImg pointer mt26" @click="refresh" />
      </div>
    </div>
    <el-dropdown class="mt20" v-if="!batchType" @command="selectBatchType">
      <el-button plain>
        批量调度<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="3">指派接力骑手</el-dropdown-item>
        <el-dropdown-item command="1">指派骑手</el-dropdown-item>
        <el-dropdown-item command="2">转派骑手</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="select-box" v-else>
      <i class="el-icon-close" @click="closeBatchHandle"></i>
      <span>已选{{multipleSelection.length}}条订单数据</span>
      <el-button type="primary" v-if="batchType == '3'" @click="batchHandle('3')">指派接力骑手</el-button>
      <el-button type="primary" v-if="batchType == '1'" @click="batchHandle('1')">指派骑手</el-button>
      <el-button type="primary" v-if="batchType == '2'" @click="batchHandle('2')">转派骑手</el-button>
    </div>
    <!-- table表格区域 -->
    <div class="table-box">
      <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center" v-if="batchType"></el-table-column>
        <el-table-column label="订单类型">
          <template slot-scope="scope">
            <span>{{
              scope.row.orderType === 1
                ? "外卖配送"
                : orderTypeDataLists[scope.row.orderSubType]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="单号">
          <template slot-scope="scope">
            <p class="ticketNumber mt14" v-if="scope.row.transCode">票号：{{ scope.row.transCode }}</p>
            <p class="ticketNumber mt14" v-if="scope.row.orderNo">流水号：#{{ scope.row.orderNo }}</p>
            <p class="mt14">配送单号：{{ scope.row.skOrderId }}</p>
            <p class="mt14">商户订单号：{{ scope.row.shopOrderId }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="" label="订单信息" width="240">
          <template slot-scope="scope">
            <div class="mt14">商家名称：{{ scope.row.merchantName || '-' }}</div>
            <div class="mt14">
              骑手：{{ scope.row.riderName }}-{{ scope.row.riderPhone }}
            </div>
            <div v-if="scope.row.orderType === 1">
              <div class="mt14">下单时间：{{ scope.row.createTime }}</div>
              <div class="mt14" v-if="scope.row.state == 5">
                送达时间：{{ scope.row.realArriveTime }}
              </div>
              <div class="mt14" v-else>
                预计送达：{{ scope.row.preArriveTime }}
              </div>
            </div>
            <div v-else>
              <div class="mt14">下单时间：{{ scope.row.createTime }}</div>
              <div class="mt14" v-if="scope.row.orderSubType === 1">
                物品信息：{{
                  getRowValue(scope.row, "orderDetail.helpTake.goodsInfo")
                }}-
                {{
                  getRowValue(scope.row, "orderDetail.helpTake.goodsWeight") ||
                  0
                }}kg
              </div>
              <div class="mt14" v-else-if="scope.row.orderSubType === 2">
                物品信息：{{
                  getRowValue(scope.row, "orderDetail.helpDeliver.goodsInfo")
                }}-
                {{
                  getRowValue(
                    scope.row,
                    "orderDetail.helpDeliver.goodsWeight"
                  ) || 0
                }}kg
              </div>
              <div class="mt14" v-else-if="scope.row.orderSubType === 3">
                物品信息：{{
                  getRowValue(scope.row, "orderDetail.helpBuy.goodsInfo")
                }}-
                {{
                  getRowValue(scope.row, "orderDetail.helpBuy.goodsWeight") ||
                  0
                }}kg
              </div>
              <div class="mt14" v-else-if="scope.row.orderSubType === 6">
                <p>
                  排队内容：{{
                    getRowValue(
                      scope.row,
                      "orderDetail.helpLine.description"
                    ) || "—"
                  }}
                </p>
                <p>
                  开始时间：{{
                    getRowValue(
                      scope.row,
                      "orderDetail.helpLine.lineStartTime"
                    ) || "立即前往"
                  }}
                </p>
              </div>
              <div class="mt14" v-else-if="scope.row.orderSubType === 7">
                <p>
                  帮办内容：{{
                    getRowValue(
                      scope.row,
                      "orderDetail.helpAlmighty.description"
                    ) || "—"
                  }}
                </p>
                <p>
                  开始时间：{{
                    getRowValue(
                      scope.row,
                      "orderDetail.helpAlmighty.workStartTime"
                    ) || "立即前往"
                  }}
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="收发地址" min-width="340">
          <!--orderType=1时此处值为1 orderType=2 1帮我取 2帮我送 3帮我买  >>>>> 4跑腿 5大件业务 6帮排队 7全能帮手 -->
          <template slot-scope="scope">
            <div v-if="scope.row.orderSubType <= 3">
              <p>
                <span v-if="scope.row.orderSubType <= 2">取：</span>
                <span v-else-if="
                    scope.row.orderType !== 1 && scope.row.orderSubType === 3
                  ">买：</span>
                <span>{{ scope.row.merchantName }}-</span>
                <span>{{ scope.row.merchantTel }}-</span>
                <span>{{ scope.row.merchantAddress }}</span>
              </p>
              <p>
                送：
                <span>{{ scope.row.acceptName }}-</span>
                <span>{{ scope.row.acceptTel }}-</span>
                <span>{{ scope.row.acceptAddress }}</span>
              </p>
              <p v-if="scope.row.acceptDesc">备注：{{scope.row.acceptDesc}}</p>
            </div>
            <!-- 帮排队 全能帮手 -->
            <div v-if="scope.row.orderSubType >= 6 && scope.row.orderType != 1">
              <p>
                <span v-if="scope.row.orderSubType === 6">排队地址：</span>
                <span v-else>地址：</span>
                <span>{{ scope.row.acceptAddress }}</span>
              </p>
              <p>
                联系人姓名：<span>{{ scope.row.acceptName }}</span>
              </p>
              <p>
                联系电话：<span>{{ scope.row.acceptTel }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单来源" width="90" align="center">
          <template slot-scope="scope">
            <span>{{ getOrderSource(scope.row) }}</span>
            <!-- <span v-if="scope.row.orderType === 2 && scope.row.accessSubId">
              -{{
                scope.row.accessSubId === 1 || scope.row.accessSubId === 10000
                  ? "平台"
                  : "商圈"
              }}
            </span> -->
          </template>
        </el-table-column>
        <el-table-column prop="orderDistance" align="center" label="配送方式" width="100">
          <template slot-scope="scope">
            <span>{{ psMethods[scope.row.orderSource] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderDistance" align="center" label="距离">
          <template slot-scope="scope">
            <span>{{ scope.row.orderDistance }}km</span>
          </template>
        </el-table-column>
        <el-table-column prop="remainingTime" align="center" label="剩余配送时长">
          <template slot-scope="scope">
            <span v-if="scope.row.remainingTime >= 0">{{ scope.row.remainingTime }}分钟</span>
            <span v-else>超时{{ Math.abs(scope.row.remainingTime) }}分钟</span>
          </template>
        </el-table-column>
        <el-table-column prop="exceptionMsg" align="center" label="异常信息">
          <template slot-scope="scope">
            <span class="info" v-if="scope.row.exceptionMsg">{{
              scope.row.exceptionMsg
            }}</span>
            <span class="info" v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="配送状态">
          <template slot-scope="scope">
            <div v-if="scope.row.orderProperty == 1">
              <span>{{ stateOptions[scope.row.state].label }}</span>
            </div>
            <div v-if="scope.row.orderProperty == 2">{{riderStage(scope.row.riderDeliverCount,scope.row.riderState)}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <!-- 订单调度 订单查询 都不要订单详情 -->
            <!-- <el-button type="text" v-if="+scope.row.orderSource !== 2"
              @click="openOrderDetailDialog(scope.row.skOrderId, scope.row.state)">
              订单详情</el-button> -->
            <el-button type="text" v-if="
                Number(scope.row.riderId) > 0 && Number(scope.row.state) <= 4
              " @click="riderposition(scope.row.riderId)">
              骑手定位</el-button>
            <el-button type="text" class="info" @click="openRiderDialog(scope.row.skOrderId, 2)" 
              v-if="scope.row.state == 1 && !batchType">指派骑手
            </el-button>
            <!-- 普通订单转派骑手 -->
            <el-button type="text" class="info" @click="openRiderDialog(scope.row.skOrderId, 3)" v-if="
              scope.row.orderProperty==1 && (scope.row.state == 2 ||scope.row.state == 3 ||scope.row.state == 4) && !batchType
              ">转派骑手</el-button>
            <!-- 接力订单转派骑手 -->
            <el-button type="text" class="info" @click="openRiderDialog(scope.row.skOrderId, 3)"
              v-if="scope.row.orderProperty==2 && scope.row.riderState!==4&&scope.row.riderState!==5&&scope.row.riderState!==6
              &&scope.row.riderDeliverCount==1 && !batchType">
              转派骑手
            </el-button>
            <!-- todo 先暂时禁用转第三方的按钮 后面功能调试好了之后再放开 -->
            <!-- <el-button type="text" class="info" @click="resendThirdPlat(scope.row)"
              v-if="scope.row.state < 5">转第三方</el-button> -->
            <!-- scope.row.orderSource == 1 &&  美团订单也可以转第三方 -->
            <!-- 接力订单指派接力骑手 -->
            <el-button type="text" class="info" v-if="scope.row.orderType===1&&scope.row.riderState==5&&openRelayOrderConfig&& !batchType"
              @click="openAppointRiderDialog(scope.row)">
              指派接力骑手
            </el-button>
            <!-- <el-button type="text" class="info" @click="openAppointRiderDialog(scope.row)">
              指派接力骑手
            </el-button> -->
            <el-button type="text" class="info"
              v-if="scope.row.orderType != 2 && scope.row.orderProperty==1&&scope.row.state!==1&&scope.row.state!==6"
              @click="openRecords(scope.row)">配送记录
            </el-button>
            <el-button type="text" class="info"
              v-if="scope.row.orderType != 2 && scope.row.orderProperty==2" @click="openRecords(scope.row)">配送记录
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>

    <!-- 指派骑手弹窗 -->
    <el-dialog title="指派骑手" :visible.sync="riderDialog" width="1300px" class="rider-dialog">
      <div class="rider-dialog-content">
        <div class="rider-dialog-tabel">
          <div class="rider-dialog-search">
            <span class="mr10">查询骑手</span>
            <el-input v-model="riderInfo" class="inp" clearable placeholder="输入骑手名字或电话" :maxlength="20"></el-input>
            <el-button type="primary" class="btn" @click="searchRider('search')">查询</el-button>
          </div>
          <div class="rider-dialog-table">
            <el-table :data="riderTableData" border style="width: 100%" height="400" ref="riderTable" @row-click="selectRider"
              :row-style="rowStyle" :row-class-name="tableRowClassName" v-loading="riderLoading" element-loading-text="数据获取中...">
              <el-table-column prop="riderName" align="center" label="姓名"></el-table-column>
              <el-table-column prop="riderPhone" align="center" label="电话"></el-table-column>
              <el-table-column align="center" label="距离商户">
                <template slot-scope="scope">
                  <span> {{ scope.row.riderDistance }}km </span>
                </template>
              </el-table-column>
              <el-table-column prop="riderState" align="center" label="当前骑手状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.riderState == 3">配送中</span>
                  <span v-if="scope.row.riderState == 2">抢单中</span>
                </template>
              </el-table-column>
              <el-table-column prop="professionType" align="center" label="岗位">
                <template slot-scope="scope">
                  <span v-if="scope.row.professionType == 1">全职</span>
                  <span v-if="scope.row.professionType == 2">兼职</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination class="mt10" background :page-count="riderTotalPage" :current-page.sync="riderCurrentPage"
            @current-change="riderPageChange(1)">
          </el-pagination>
          <div class="rider-dialog-footerBtn">
            <el-button @click="riderDialog = false">取消</el-button>
            <PrevReclick>
              <el-button type="primary" @click="assignRider">确认指派</el-button>
            </PrevReclick>
          </div>
        </div>
        <div class="rider-dialog-tabel">
          <div class="rider-riderOrderInfo">
            <span class="mr10">骑手当前已接订单信息</span>
            <span>今日单量{{
                orderInfo.riderOrderStatisticVo.underwayOrderCount || 0
              }}/
              {{
                orderInfo.riderOrderStatisticVo.totalOrder || 0
              }}(进行中/总接单量)</span>
            <span class="rider-dialog-riderPort" @click="riderposition(RiderOrderInfo.riderId)">骑手定位</span>
          </div>
          <div class="rider-dialog-table">
            <el-table :data="orderInfo.riderOrderInfos.records" border style="width: 100%" height="400" ref="riderTable"
              v-loading="riderOrderInfoLoading" element-loading-text="数据获取中...">
              <el-table-column align="center" label="订单类型">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.orderType === 1
                      ? "外卖配送"
                      : orderTypeDataLists[scope.row.orderSubType]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="状态(已用时)" width="140">
                <!-- 用时  当前时间减 接单时间 -->
                <template slot-scope="scope">
                  <p>{{ dialogOrderType[scope.row.orderState] }}</p>
                  <p>({{ getMinutes(scope.row.receiveTime, 1) }}min)</p>
                </template>
              </el-table-column>
              <el-table-column align="center" label="期望送达时间" width="160">
                <template slot-scope="scope">
                  <div v-if="scope.row.orderType === 1">
                    <p>{{ scope.row.expectArriveTime }}</p>
                    <p>
                      剩余时间：{{
                        getMinutes(scope.row.expectArriveTime, 2)
                      }}min
                    </p>
                    <!-- 期望送达时间 - 当前时间 -->
                  </div>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="商家名称" width="180" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span v-if="scope.row.orderType === 1">{{
                    scope.row.shopName || "-"
                  }}</span>
                  <span v-else>-</span>
                </template>
              </el-table-column>

              <el-table-column label="收发地址" width="200">
                <template slot-scope="scope">
                  <div v-if="scope.row.orderSubType <= 3">
                    <p>
                      <span v-if="scope.row.orderSubType <= 2">取：</span>
                      <span v-else-if="
                          scope.row.orderType !== 1 &&
                          scope.row.orderSubType === 3
                        ">买：</span>
                      <span>{{ scope.row.shopName }}-</span>
                      <span>{{ scope.row.shopTel }}-</span>
                      <span>{{ scope.row.fetchAddress }}</span>
                    </p>
                    <p>
                      送：
                      <span>{{ scope.row.contactName }}-</span>
                      <span>{{ scope.row.contactTel }}-</span>
                      <span>{{ scope.row.deliveryAddress }}</span>
                    </p>
                  </div>
                  <!-- 帮排队 全能帮手 -->
                  <div v-if="
                      scope.row.orderSubType >= 6 && scope.row.orderType != 1
                    ">
                    <p>
                      <span v-if="scope.row.orderSubType === 6">排队地址：</span>
                      <span v-else>地址：</span>
                      <span>{{ scope.row.deliveryAddress }}</span>
                    </p>
                    <p>
                      联系人姓名：<span>{{ scope.row.contactName }}</span>
                    </p>
                    <p>
                      联系电话：<span>{{ scope.row.contactTel }}</span>
                    </p>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination class="mt10" background 
            :page-count="orderInfo.riderOrderInfos.pages || 0" :current-page.sync="RiderOrderInfo.pageCurrent"
            @current-change="riderPageChange(2)" layout="total,prev,pager,next">
          </el-pagination>
        </div>
      </div>
      <span slot="footer"></span>
    </el-dialog>
    <!-- 转派记录弹窗 -->
    <el-dialog
      title="转派记录"
      :visible.sync="riderRedeployDialog"
      width="1000px"
      :close-on-click-modal="false"
      >
      <el-table
        :data="riderRedeployData"
        border
        style="width: 100%">
        <el-table-column prop="skOrderId" label="配送单号" align="center"></el-table-column>
        <el-table-column prop="deliveryId" label="商户单号" align="center"></el-table-column>
        <el-table-column prop="" label="原骑手" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.oldRiderName}} </span>
            <span>{{scope.row.oldRiderPhone}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="转派骑手" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.newRiderName}} </span>
            <span>{{scope.row.newRiderPhone}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="redeployDate" label="转派时间" align="center"></el-table-column>
        <el-table-column prop="" label="当前状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">待接单</span>
            <span v-else-if="scope.row.state == 2">已接单</span>
            <span v-else-if="scope.row.state == 3">已到店</span>
            <span v-else-if="scope.row.state == 4">配送中</span>
            <span v-else-if="scope.row.state == 5">配送完成</span>
            <span v-else-if="scope.row.state == 6">用户撤销订单</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="mt20"
        background :page-count="riderRedeployTotalPage"
        :current-page.sync="riderRedeployCurrentPage" @current-change="riderRedeployPageChange"></el-pagination>
    </el-dialog>
    <!-- 订单详情弹窗 -->
    <orderDetail ref="orderDetail"></orderDetail>
    <!-- 指定接力骑手骑手弹窗  -->
    <el-dialog :visible.sync="appointRiderDialogVisible" class="appointRider" title="指定下一个接力骑手">
      <!-- 左边内容 -->
      <div class="left-content">
        <div class="search-rider">
          <p>查询骑手</p>
          <el-input v-model="appointRider" clearable style="width:60%;margin-right:15px;" placeholder="请输入骑手名字或电话"
            @clear='clearAppointRirder'></el-input>
          <button @click="searchAppointRider">查询</button>
        </div>
        <el-table :data="appointRiderTableData" border height="400" highlight-current-row
        @current-change="handleCurrentChange" :row-style="rowStyle">
          <el-table-column prop="riderName" label="姓名"> </el-table-column>
          <el-table-column prop="riderPhone" label="电话"> </el-table-column>
          <el-table-column align="center" label="距离餐品">
            <template slot-scope="scope">
              <span> {{ scope.row.riderDistance }}km </span>
            </template>
          </el-table-column>
          <el-table-column prop="riderState" align="center" label="当前骑手状态">
            <template slot-scope="scope">
              <span v-if="scope.row.riderState == 3">配送中</span>
              <span v-if="scope.row.riderState == 2">抢单中</span>
            </template>
          </el-table-column>
          <el-table-column prop="professionType" align="center" label="岗位">
            <template slot-scope="scope">
              <span v-if="scope.row.professionType == 1">全职</span>
              <span v-if="scope.row.professionType == 2">兼职</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination background @current-change="handleAppointCurrentPage" :total="appointTotal" :current-page="appointCurrentPage"
          :page-size="appointPageSize" layout="total,prev,pager,next,jumper"></el-pagination>
        <div class="btns">
          <button @click="confirmOrderRider">确定指派</button>
          <button @click="cansoleOrderRider">取消</button>
        </div>
      </div>
      <!-- 右边内容 -->
      <div class="right-content">
        <div class="right-content-title">
          <p>骑手当前已接订单信息</p>
          <p>
            今日单量{{
              appointRiderInfo.riderOrderStatisticVo.underwayOrderCount
            }}/{{ appointRiderInfo.riderOrderStatisticVo.totalOrder }}（进行中/总接单量）
          </p>
          <p class="rider-location pointer" @click="riderposition(appointRiderId)">骑手定位</p>
        </div>
        <el-table :data="appointRiderInfo.riderOrderInfos.records" border height="400">
          <el-table-column align="center" label="订单类型">
            <template slot-scope="scope">
              <span>{{
                    scope.row.orderType === 1
                      ? "外卖配送"
                      : orderTypeDataLists[scope.row.orderSubType]
                  }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="状态(已用时)" width="140">
            <!-- 用时  当前时间减 接单时间 -->
            <template slot-scope="scope">
              <p>{{ dialogOrderType[scope.row.orderState] }}</p>
              <p>({{ getMinutes(scope.row.receiveTime, 1) }}min)</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="期望送达时间" width="160">
            <template slot-scope="scope">
              <div v-if="scope.row.orderType === 1">
                <p>{{ scope.row.expectArriveTime }}</p>
                <p>
                  剩余时间：{{
                        getMinutes(scope.row.expectArriveTime, 2)
                      }}min
                </p>
                <!-- 期望送达时间 - 当前时间 -->
              </div>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="商家名称" width="180" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.orderType === 1">{{
                    scope.row.shopName || "-"
                  }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column label="收发地址" width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.orderSubType <= 3">
                <p>
                  <span v-if="scope.row.orderSubType <= 2">取：</span>
                  <span v-else-if="
                          scope.row.orderType !== 1 &&
                          scope.row.orderSubType === 3
                        ">买：</span>
                  <span>{{ scope.row.shopName }}-</span>
                  <span>{{ scope.row.shopTel }}-</span>
                  <span>{{ scope.row.fetchAddress }}</span>
                </p>
                <p>
                  送：
                  <span>{{ scope.row.contactName }}-</span>
                  <span>{{ scope.row.contactTel }}-</span>
                  <span>{{ scope.row.deliveryAddress }}</span>
                </p>
              </div>
              <!-- 帮排队 全能帮手 -->
              <div v-if="
                      scope.row.orderSubType >= 6 && scope.row.orderType != 1
                    ">
                <p>
                  <span v-if="scope.row.orderSubType === 6">排队地址：</span>
                  <span v-else>地址：</span>
                  <span>{{ scope.row.deliveryAddress }}</span>
                </p>
                <p>
                  联系人姓名：<span>{{ scope.row.contactName }}</span>
                </p>
                <p>
                  联系电话：<span>{{ scope.row.contactTel }}</span>
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mt10" background @current-change="handleAppointRiderCurrentPage" :page-count="appointRiderTotal"
          :current-page.sync="appointRiderCurrentPage" layout="total,prev,pager,next"></el-pagination>
      </div>
    </el-dialog>
    <!-- 配送详情弹窗 -->
    <deliveryRecordDialog ref="deliverDialog"></deliveryRecordDialog>
    <!-- 批量调度结果弹窗 -->
    <el-dialog
      title="提示"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      center
      :visible.sync="batchResultDialogVisible"
      width="1000px">
      <el-table :data="multipleSelection" border height="500" style="width: 100%">
        <el-table-column prop="" label="序号" width="100">
          <template slot-scope="scope">
            <span>{{scope.$index + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="单号">
          <template slot-scope="scope">
            <span>配送单号：{{scope.row.skOrderId}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status && scope.row.status === '1'" style="color: #ed3e45">{{scope.row.msg}}</span>
            <span v-else>{{scope.row.msg}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="batch-result-tips">注：取消操作不影响已执行完成的配送单</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="batchEnd" @click="cancelBatchFunction">已知晓</el-button>
        <el-button type="info" v-else @click="cancelBatchFunction">取消操作</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import orderDetail from '@/components/order/orderDetail.vue';
import deliveryRecordDialog from '@/components/order/deliveryRecordDiaLog.vue';
import {
  $sg_queryOrderDispatch,
  $sp_pageOnlineRider,
  $sp_orderManagementSuccess,
  $sg_getRiderOrderInfos,
  $sg_toThirdChannel,
  $sg_replayOrder,
  $sg_queryOpenRelayTokenByAgentId,
  $sg_queryRiderRedeployByAgent,
} from '@/api/delivery';

export default {
  data() {
    return {
      form: {
        exception: '', // 是否异常
        orderState: '', // 订单状态
        riderNameOrRiderPhone: '', // 骑手姓名
        orderNo: '', // 流水号
        merchantName: '', // 商户
        orderSource: '', // 订单来源
        acceptNameOrAcceptPhone: '', // 收件人
        orderSubType: '', // 订单类型
        orderProperty: '', // 订单属性 1 正常 2接力
        orderNumber: '', // 单号搜索
        acceptOrMerchantAddress: '', // 收发地址
      },
      time: '',
      mode: '',
      psMethods: ['', '松鼠配送', '松鼠+三方配送', '松鼠配送'], // 配送方式
      orderTypeDataLists: [
        '',
        '帮我取',
        '帮我送',
        '帮我买',
        '',
        '',
        '帮排队',
        '全能帮手',
        '外卖配送',
      ],
      orderType: [
        { value: '', label: '全部' },
        { value: 1, label: '帮我取' },
        { value: 2, label: '帮我送' },
        { value: 3, label: '帮我买' },
        // { value: 4, label: '跑腿' },
        // { value: 5, label: '大件业务' },
        { value: 6, label: '帮排队' },
        { value: 7, label: '全能帮手' },
        { value: 8, label: '外卖配送' },
        { value: 9, label: '跑腿业务' },
      ],
      dialogOrderType: ['', '已接单', '已到店', '配送中'],
      stateOptions: [
        // 1骑手待接单 2骑手已接单 3已到店 4配送中
        { value: '', label: '全部' },
        { value: '1', label: '骑手待接单' },
        { value: '2', label: '已接单' },
        { value: '3', label: '已到店' },
        { value: '4', label: '配送中' },
        { value: '5', label: '阶段配送完成' },
      ],
      orderOptions: [
        { value: '', label: '全部' },
        { value: '1', label: '正常订单' },
        { value: '2', label: '异常订单' },
      ],
      orderSourceOptions: [
        { value: '', label: '全部' },
        { value: 1, label: '松鼠订单' },
        { value: 2, label: '三方' },
        { value: 3, label: '松鼠跑腿' },
      ],
      tableData: [],
      totalPage: 0,
      currentPage: 1,
      riderDialog: false,
      riderInfo: '',
      riderTotalPage: 0,
      riderCurrentPage: 1,
      riderTableData: [],
      orderRiderRequest: {
        orderId: '',
        riderId: '',
        riderLat: '',
        riderLng: '',
      },
      // 日期选择器可选择范围
      dateRange: {
        disabledDate(time) {
          // 限制最早只能从5月份开始查询,最大为当天
          return (
            new Date(time).getTime() < new Date('2021-04-30').getTime()
            || new Date(time).getTime() > new Date().getTime()
          );
        },
      },
      assignRiderType: null, // 2.平台指派 3.平台转派
      RiderOrderInfo: {
        // 获取骑手接单信息
        riderId: null,
        pageCurrent: 1,
        pageSize: 10,
      },
      orderInfoTotal: 1,
      riderLoading: false, // 骑手列表加载
      riderOrderInfoLoading: false, // 骑手订单信息加载
      orderInfo: {
        riderOrderInfos: {}, // 订单列表
        riderOrderStatisticVo: {}, // 订单统计
      }, // 骑手订单信息
      appointRiderDialogVisible: false, // 指定/转派骑手弹窗
      appointCurrentPage: 1,
      appointTotal: 0,
      appointPageSize: 10,
      appointRider: '',
      appointRiderId: null, // 骑手id
      appointRiderTableData: [],
      recordsDialog: false, // 配送记录
      recordsId: '', // 配送记录订单ID
      appointRiderInfo: {
        riderOrderInfos: {}, // 订单列表
        riderOrderStatisticVo: {}, // 订单统计
      },
      appointRiderRequest: {
        orderId: '',
        riderId: '',
      },
      currentRow: '', // 当前选择的指派接力骑手信息
      // 骑手信息分页
      appointRiderCurrentPage: 1,
      appointRiderPageSize: 10,
      appointRiderTotal: 0,
      openRelayOrderConfig: '', // 查询代理商是否开启了转单设置
      maxRiderLevel: '', // 代理商设置的最大转单层数
      riderRedeployDialog: false, // 转派记录弹窗
      riderRedeployData: [], // 转派记录
      riderRedeployTotalPage: 0,
      riderRedeployCurrentPage: 1,
      batchType: '', // 批量调度类型
      multipleSelection: [],
      batchResultDialogVisible: false, // 批量调度结果弹窗
      batchEnd: false,
    };
  },
  components: {
    orderDetail,
    deliveryRecordDialog,
  },
  created() {
    this.selectMode('4');
    this.search();
  },
  methods: {
    getOrderSource({ orderSource, orderType }) {
      if (orderSource === 1) {
        return orderType === 1 ? '松鼠订单' : '跑腿订单';
      }
      return '三方';
    },
    getRowValue(row, key) {
      const keyArray = key.split('.');
      let newRow = row;
      for (let i = 0; i < keyArray.length; i += 1) {
        const keyItem = keyArray[i];
        if (newRow[keyItem]) {
          newRow = newRow[keyItem];
        } else {
          newRow = null;
          break;
        }
      }
      return newRow;
    },
    search(e) {
      this.btnBlur(e);
      this.tableData = [];
      this.totalPage = 0;
      this.currentPage = 1;
      this.batchType = '';
      this.getList();
    },
    getList(batchType) {
      const form = JSON.parse(JSON.stringify(this.form));
      let params = {};
      if (form.orderState === '5') {
        form.orderProperty = 2;
        form.orderState = '';
        params = {
          pageCurrent: this.currentPage,
          pageSize: 10,
          ...form,
        };
      } else {
        form.orderProperty = form.orderState ? 1 : '';
        params = {
          pageCurrent: this.currentPage,
          pageSize: 10,
          ...form,
        };
      }
      params.batchType = batchType || '';
      params.orderStartDate = this.time && this.time.length > 1 ? this.time[0] : '';
      params.orderEndDate = this.time && this.time.length > 1 ? this.time[1] : '';
      $sg_queryOrderDispatch(params).then((res) => {
        this.totalPage = res.pages;
        this.tableData = res.records;
      });
      // 查询代理商是否开启了转单设置
      $sg_queryOpenRelayTokenByAgentId({ businessType: 1 }).then((v) => {
        this.openRelayOrderConfig = v.openRelayOrderConfig;
        this.maxRiderLevel = v.maxRiderLevel;
      });
    },
    btnBlur(e) {
      if (e && e.type === 'click') {
        let target = e.target;
        if (target.nodeName === 'SPAN') {
          target = e.target.parentNode;
        }
        target.blur();
      }
    },
    // 选择订单日期
    selectMode(mode) {
      const date = new Date().getTime() / 1000;
      // 获取今天日期
      const today = this.$formatDate(date, 'yyyy-MM-dd');
      // 获取昨天日期
      const yesterday = this.$formatDate(date - 1 * 24 * 60 * 60, 'yyyy-MM-dd');
      // 获取7天前日期
      const beforeSevenDay = this.$formatDate(
        date - 7 * 24 * 60 * 60,
        'yyyy-MM-dd',
      );
      // 获取30天前日期
      const beforeThirtyDay = this.$formatDate(
        date - 30 * 24 * 60 * 60,
        'yyyy-MM-dd',
      );
      switch (mode) {
        // 今天
        case '1':
          this.time = [today, today];
          break;
        // 昨天
        case '2':
          this.time = [yesterday, yesterday];
          break;
        // 近7日
        case '3':
          this.time = [beforeSevenDay, today];
          break;
        // 近30日
        case '4':
          this.time = [beforeThirtyDay, today];
          break;
        default:
          break;
      }
      this.mode = mode;
    },
    // 日期选择器改变事件
    timeChange(val) {
      if (!val) {
        this.selectMode('1');
      } else {
        this.mode = '';
      }
    },
    // 清空
    clear() {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = '';
      });
      this.selectMode('1');
      this.currentPage = 1;
      this.batchType = '';
      this.getList();
    },
    // 转派记录
    queryRiderRedeployByAgent() {
      this.riderRedeployDialog = true;
      const params = {
        pageCurrent: this.riderRedeployCurrentPage,
        pageSize: 10,
        queryDateType: 0,
      };
      $sg_queryRiderRedeployByAgent(params).then((res) => {
        this.riderRedeployTotalPage = res.pages;
        this.riderRedeployData = res.records;
      });
    },
    // 转派记录翻页
    riderRedeployPageChange() {
      this.queryRiderRedeployByAgent();
    },
    // 刷新
    async refresh() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.batchType = '';
      this.getList();
      loading.close();
      // const params = {
      //   pageCurrent: this.currentPage,
      //   pageSize: 15,
      //   ...this.form,
      // };
      // params.orderStartDate = this.time && this.time.length > 1 ? this.time[0] : '';
      // params.orderEndDate = this.time && this.time.length > 1 ? this.time[1] : '';
      // $sg_queryOrderDispatch(params).then((res) => {
      //   this.tableData = res.records;
      //   this.totalPage = res.pages;
      //   loading.close();
      // });
    },
    // 批量调度选择
    selectBatchType(e) {
      this.batchType = e;
      this.multipleSelection = [];
      this.currentPage = 1;
      this.getList(e);
    },
    // 选择批量调度数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量调度操作
    batchHandle(e) {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择批量调度数据');
        return;
      }
      if (e === '1') {
        this.openRiderDialog('', 2);
      } else if (e === '2') {
        this.openRiderDialog('', 3);
      } else if (e === '3') {
        this.openAppointRiderDialog({});
      }
    },
    // 关闭批量调度
    closeBatchHandle() {
      this.batchType = '';
      this.multipleSelection = [];
      this.currentPage = 1;
      this.getList();
    },
    // 打开订单详情弹窗
    // openOrderDetailDialog(skOrderId, state) {
    //   this.$refs.orderDetail.open(skOrderId, state);
    // },
    // 查看骑手定位
    riderposition(riderId) {
      if (!riderId) {
        this.$message.error('获取骑手位置失败');
        return;
      }
      this.$router.push({
        path: '/main/delivery/riderposition',
        query: {
          riderId,
        },
      });
    },
    // 订单转第三方
    resendThirdPlat({ skOrderId }) {
      this.$confirm(
        '转第三方配送产生的配送差价需代理商补贴，确认转三方？',
      ).then(() => {
        $sg_toThirdChannel({ skOrderId })
          .then(() => {
            this.refresh();
            this.$message({
              message: '操作成功',
              type: 'success',
            });
          })
          .catch((err) => {
            if (err.msg) {
              this.$message.error(err.msg);
            }
          });
      });
    },
    // 打开指派骑手弹窗
    openRiderDialog(orderId, type) {
      this.RiderOrderInfo.riderId = null;
      this.RiderOrderInfo.pageCurrent = 1;
      this.orderInfo.riderOrderInfos = {};
      this.orderInfo.riderOrderStatisticVo = {};
      this.assignRiderType = type;
      this.name = false;
      this.orderRiderRequest = {
        orderId,
      };
      this.riderDialog = true;
      this.searchRider();
    },
    // 查询骑手
    searchRider(type = '') {
      const params = {
        pageCurrent: this.riderCurrentPage,
        pageSize: 10,
        searchKey: this.riderInfo,
      };
      this.riderLoading = true;
      $sp_pageOnlineRider(params)
        .then((res) => {
          this.riderTableData = res.records;
          this.riderTotalPage = res.pages;
          if (!res.total && type) {
            this.$message.error('请输入正确信息');
          }
        })
        .finally(() => {
          this.riderLoading = false;
        });
    },
    // 获取骑手接单信息
    getRiderOrderInfos() {
      $sg_getRiderOrderInfos(this.RiderOrderInfo)
        .then((res) => {
          const { riderOrderInfos, riderOrderStatisticVo } = res;
          if (riderOrderInfos) {
            this.orderInfo.riderOrderInfos = riderOrderInfos;
          }
          if (riderOrderStatisticVo) {
            this.orderInfo.riderOrderStatisticVo = riderOrderStatisticVo;
          }
        })
        .finally(() => {
          this.riderOrderInfoLoading = false;
        });
    },
    // 骑手列表翻页
    riderPageChange(type) {
      if (type === 1) {
        this.searchRider();
      } else {
        this.getRiderOrderInfos();
      }
    },
    // 选择骑手
    selectRider(row) {
      this.orderRiderRequest.riderId = row.id;
      this.orderRiderRequest.riderLat = row.latitude;
      this.orderRiderRequest.riderLng = row.longitude;
      this.riderOrderInfoLoading = true;
      this.RiderOrderInfo.riderId = row.id;
      this.getRiderOrderInfos();
    },
    // 更改选中行背景色
    rowStyle({ row }) {
      if (this.orderRiderRequest.riderId === row.id) {
        return { 'background-color': '#F7EDED', cursor: 'pointer' };
      }
      return { cursor: 'pointer' };
    },
    // 指派骑手
    assignRider() {
      if (!this.orderRiderRequest.riderId) {
        this.$message.error('请选择骑手');
        return;
      }
      if (this.batchType) {
        // 批量调度
        this.batchResultDialogVisible = true;
        this.batchFunction(0);
      } else {
        const params = {
          ...this.orderRiderRequest,
          takeOrderType: this.assignRiderType || 2,
        };
        $sp_orderManagementSuccess(params).then(() => {
          this.riderDialog = false;
          this.getList();
          this.$message.success('操作成功');
        }).catch((err) => {
          this.$message.error(err.msg);
        });
      }
    },
    // 批量调度请求接口
    batchFunction(index) {
      if (index > this.multipleSelection.length - 1) {
        this.batchEnd = true;
        return;
      }
      this.$set(this.multipleSelection[index], 'msg', '进行中...');
      if (this.batchType === '1' || this.batchType === '2') {
        // 指派骑手/转派骑手
        const params = {
          ...this.orderRiderRequest,
          takeOrderType: this.assignRiderType || 2,
        };
        params.orderId = this.multipleSelection[index].skOrderId;
        $sp_orderManagementSuccess(params).then(() => {
          this.$set(this.multipleSelection[index], 'msg', '已完成');
          this.batchFunction(index + 1);
        }).catch((err) => {
          this.$set(this.multipleSelection[index], 'msg', err.msg);
          this.$set(this.multipleSelection[index], 'status', '1');
          this.batchFunction(index + 1);
        });
      } else if (this.batchType === '3') {
        // 指派接力骑手
        const params = {
          riderId: this.currentRow.id,
          riderLng: this.currentRow.longitude,
          riderLat: this.currentRow.latitude,
          orderId: this.multipleSelection[index].skOrderId, // 订单id
          operationType: 2,
          orderStatus: 9,
        };
        $sg_replayOrder(params).then(() => {
          this.$set(this.multipleSelection[index], 'msg', '已完成');
          this.batchFunction(index + 1);
        }).catch((err) => {
          this.$set(this.multipleSelection[index], 'msg', err.msg);
          this.$set(this.multipleSelection[index], 'status', '1');
          this.batchFunction(index + 1);
        });
      }
    },
    // 取消批量调度请求接口
    cancelBatchFunction() {
      this.riderDialog = false;
      this.appointRiderDialogVisible = false;
      this.batchResultDialogVisible = false;
      this.multipleSelection = [];
      this.batchType = '';
      this.batchEnd = false;
    },
    // 翻页
    pageChange() {
      this.getList();
    },
    tableRowClassName({ row }) {
      if (row.id === this.RiderOrderInfo.riderId) {
        return 'success-row';
      }
      return '';
    },
    getMinutes(time, type) {
      if (!time) {
        return '';
      }
      const nowTime = new Date().getTime();
      const oldTime = new Date(time).getTime();
      const timeDis = type === 1 ? (nowTime - oldTime) / 1000 : (oldTime - nowTime) / 1000;
      return (timeDis / 60).toFixed(0);
    },
    // 选中指定骑手
    handleCurrentChange(val) {
      if (!val) return;
      this.currentRow = val;
      this.getAppointaRiderInfo();
      this.appointRiderId = this.currentRow.id;
    },
    // 获取骑手信息
    async getAppointaRiderInfo() {
      const res = await $sg_getRiderOrderInfos({
        riderId: this.currentRow.id,
        pageCurrent: this.appointRiderCurrentPage,
        pageSize: this.appointRiderPageSize,
      });
      this.appointRiderInfo = res;
      this.appointRiderTotal = res.riderOrderInfos.pages;
    },
    // 指派接力骑手弹窗
    openAppointRiderDialog(row) {
      this.appointRiderInfo = {
        riderOrderInfos: {}, // 订单列表
        riderOrderStatisticVo: {}, // 订单统计
      };
      this.appointRiderDialogVisible = true;
      this.getAppointRiderList();
      this.appointRiderRequest.orderId = row.skOrderId;
    },
    // 取消指派骑手
    cansoleOrderRider() {
      this.appointRiderDialogVisible = false;
    },
    // 确定指派
    confirmOrderRider() {
      if (!this.currentRow || !this.currentRow.id) {
        this.$message.error('请选择骑手');
        return;
      }
      if (this.batchType) {
        // 批量调度
        this.batchResultDialogVisible = true;
        this.batchFunction(0);
      } else {
        const params = {
          riderId: this.currentRow.id,
          riderLng: this.currentRow.longitude,
          riderLat: this.currentRow.latitude,
          orderId: this.appointRiderRequest.orderId, // 订单id
          operationType: 2,
          orderStatus: 9,
        };
        $sg_replayOrder(params).then(() => {
          this.appointRiderDialogVisible = false;
          this.getList();
          this.$message.success('操作成功');
        }).catch((err) => {
          this.$message.error(err.msg);
        });
      }
    },
    // 指定骑手翻页
    handleAppointCurrentPage(val) {
      this.appointCurrentPage = val;
      this.getAppointRiderList({ searchKey: this.appointRider });
    },
    // 骑手信息翻页
    handleAppointRiderCurrentPage(val) {
      this.appointRiderCurrentPage = val;
      this.getAppointaRiderInfo();
    },
    // 获取骑手列表
    async getAppointRiderList(params = {}) {
      const res = await $sp_pageOnlineRider({
        pageCurrent: this.appointCurrentPage,
        pageSize: this.appointPageSize,
        ...params,
      });
      console.log(res);
      this.appointRiderTableData = res.records;
      this.appointTotal = res.total;
    },
    // 根据骑手名字/电话查询骑手
    searchAppointRider() {
      this.getAppointRiderList({
        searchKey: this.appointRider,
      });
    },
    // 清除
    clearAppointRirder() {
      this.getAppointRiderList();
    },
    // 打开配送记录
    openRecords(row) {
      this.$refs.deliverDialog.openDialog(row.skOrderId);
    },
    // 是否开启转单
    // async queryOpenRelay(type) {
    //   // 1 开启 2 没开启
    //   const res = await $sg_queryOpenRelayTokenByAgentId({ businessType: type });
    //   // console.log(res);
    //   return res;
    //   // if (res.openRelayOrderConfig === true) {
    //   //   return 1;
    //   // } else {
    //   //   return 2;
    //   // }
    // },
    // 判断配送第几阶段
    riderStage(stage, state) {
      if (state === 6 && stage === 1) { // 骑手放弃接单且是第一阶段
        return '待接单';
      } else if (state === 5) { // 骑手配送已完成
        return `${stage}阶段配送完成`;
      } else if (state !== 5 && stage === 1) { // 配送没完成且是第一阶段
        return '配送中';
      } else if (state !== 5 && stage !== 1) { // 没有配送完成且不是第一阶段
        return `${stage}阶段配送中`;
      } else {
        return '';
      }
    },
  },

};
</script>

<style lang="scss">
@import "@/style/var.scss";
.search {
  flex-wrap: wrap;
}
.el-table .success-row {
  color: #fff;
  background: #ef3f46 !important;
}
.fWLabel {
  font-weight: bold;
}
.orderDispatch {
  width: 100%;
  background-color: #fff;
  color: #666;
  font-size: 14px;
  padding: 20px 15px 34px 25px;
  box-sizing: border-box;
  .name {
    margin-right: 10px;
  }
  .inp {
    width: 180px;
  }
  .mode {
    display: inline-block;
    padding: 6px 26px;
    color: #999;
    border: 1px solid #999;
    border-radius: 5px;
    margin-right: 14px;
    user-select: none;
    cursor: pointer;
  }
  & > .mode:last-child {
    margin-right: 46px;
  }
  .mode-active {
    color: $--theme-color;
    border: 1px solid $--theme-color;
  }
  .time {
    margin-right: 34px;
  }
  .btn {
    width: 80px;
    height: 32px;
    margin-top: 26px;
  }
  .search-btn {
    margin-right: 20px;
  }
  .clear-btn {
    background-color: #666;
    margin-left: 0px;
  }
  .refreshImg {
    width: 50px;
    height: 50px;
  }
  .table-box {
    width: 100%;
    margin-top: 42px;
    .el-table {
      min-height: 580px;
      & > th {
        background-color: $--theme-color;
        color: #fff;
      }
    }
    .ticketNumber {
      color: #ef3f46;
      font-weight: bold;
    }
    .info {
      color: #fa8828;
    }
    .address-icon {
      width: 23.85px;
      height: 23.85px;
      margin-right: 8px;
    }
  }
  // 指派骑手弹窗
  .rider-dialog {
    .el-dialog {
      border-radius: 6px;
    }
    .el-dialog__header {
      height: 46px;
      background-color: #f8f7f7;
      padding: 0px;
      padding-left: 35px;
      border-radius: 6px;
      .el-dialog__title {
        line-height: 46px;
        font-size: 18px;
        color: #333333;
        font-weight: bold;
      }
      .el-dialog__headerbtn {
        top: 17px;
      }
    }
    .el-dialog__body {
      padding: 0 20px 0;
    }
    .el-dialog__footer {
      border-bottom: 20px solid #f8f7f7;
      border-radius: 6px;
      padding: 0;
    }
    .rider-dialog-footerBtn {
      margin: 20px 0;
      text-align: right;
    }
    .rider-dialog-content {
      display: flex;
      justify-content: space-between;
      .rider-dialog-tabel {
        width: calc(50% - 10px);
      }
      .rider-riderOrderInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        padding-top: 26px;
      }
      .rider-dialog-riderPort {
        cursor: pointer;
        color: #ef3f46;
        text-decoration: underline;
      }
    }
    .el-button {
      width: 110px;
      height: 32px;
    }
    .el-button--default {
      color: #999999;
      border: 1px solid #ececec;
    }
    .el-button--primary {
      margin-left: 20px;
      margin-right: 25px;
    }
    // 取消表格鼠标进入高亮显示
    .el-table__row:hover > td {
      background-color: transparent;
    }
    .rider-dialog-search {
      font-size: 14px;
      color: #666666;
      .btn {
        width: 80px;
        height: 32px;
        margin-left: 36px;
        background-color: #666666;
        border-color: #666666;
      }
    }
    .rider-dialog-table {
      margin-top: 26px;
      // padding: 0px 5px;
    }
  }
  .mr60 {
    margin-right: 60px;
  }
  .mt26 {
    margin-top: 26px;
  }
  .mt14 {
    margin-top: 14px;
  }
  .mt46 {
    margin-top: 46px;
  }
  // 指派/转派骑手弹窗
  .appointRider {
    .el-dialog {
      min-width: 1200px;
      .el-dialog__body {
        border-radius: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        .el-table th {
          background: #f8f7f7;
          color: #4c4c4c;
        }
      }
    }
  }

  .left-content {
    width: 49%;
    .search-rider {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      p {
        font-size: 14px;
        color: black;
        margin-right: 5px;
      }
      button {
        width: 80px;
        height: 30px;
        border: none;
        color: #fff;
        border-radius: 8px;
        font-size: 18px;
        background: #323542;
      }
    }
    .btns {
      margin-top: 15px;
      display: flex;
      justify-content: space-around;
      button {
        width: 30%;
        height: 35px;
        font-size: 16px;
        color: #fff;
        border-radius: 10px;
        background: #ed3e45;
        border: 0px;
      }
      button:nth-child(2) {
        background: #323542;
      }
    }
  }
  .right-content {
    width: 49%;

    .right-content-title {
      display: flex;
      justify-content: space-around;
      font-size: 16px;
      color: black;
      margin-bottom: 9px;

      p {
        line-height: 42px;
      }
      .rider-location {
        text-decoration: underline;
        color: #ed3e45;
      }
    }
  }
  .select-box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    & > i {
      font-size: 24px;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }
    & > span {
      margin: 0px 10px;
    }
  }
  .batch-result-item {
    width: 100%;
    margin-top: 10px;
    & > span:first-child {
      margin-right: 15px;
    }
  }
  .batch-result-tips {
    margin-top: 10px;
    font-size: 12px;
    color: #ed3e45;
  }
}
</style>
