<template>
  <div class="distribution">
    <infoListBorder title="优先全职骑手抢单设置">
      <div class="push-content ml10">
        <el-input size='small' class="inputInfo" type='number' oninput="if(value.length > 3) value=value.slice(0, 3)" 
          clearable v-model="pushDims.configCondList[0].pushNum"></el-input>
        分钟无全职骑手接单，兼职骑手显示并可抢单
      </div>
    </infoListBorder>
    <div class="bottom-btn-group">
      <PrevReclick>
        <el-button size='small' @click="submitInfo(2)" type='primary' plain :loading="isSubmit" :disabled="isSubmit">返回上一页</el-button>
      </PrevReclick>
      <el-button size='small' @click="submitInfo(0)" type='primary' :loading="isSubmit" :disabled="isSubmit">提交设置</el-button>
    </div>
  </div>
</template>

<script>
import { deepClone } from '@base/utils';
import { $sp_psConfigAdd, $sp_psConfigUpdate } from '@/api/setting';
import infoListBorder from './infoListBorder.vue';

export default {
  components: {
    infoListBorder,
  },
  data() {
    return {
      isSubmit: false,
      times: ['', '一', '二', '三'],
      pushDims: [// 订单推送设置

      ],
      thirdPartyOrder: null, // 三方订单设置
    };
  },
  created() {
    const { pushConfigList } = this.$parent.fromSettinInfo.runErrandsConfig;
    this.pushDims = deepClone(pushConfigList[0]);
    this.thirdPartyOrder = !this.$parent.thirdPartyOrder ? 0 : 1;
  },
  methods: {
    // 提交信息 进入下一环节
    submitInfo(val) {
      if (val) {
        this.$parent.setNextPage(val);
        return;
      }
      const methodLists = [$sp_psConfigAdd, $sp_psConfigUpdate];
      const methodsType = this.$parent.settingType === 'add' ? 0 : 1;
      this.pushDims.configCondList[0].pushNum = +this.pushDims.configCondList[0].pushNum;
      this.$parent.fromSettinInfo.runErrandsConfig.pushConfigList[0] = this.pushDims;
      this.isSubmit = true;
      methodLists[methodsType]({ ...this.$parent.fromSettinInfo }).then(() => {
        this.$parent.saveSuccess();
        this.$message({
          message: '设置成功',
          type: 'success',
        });
      }).catch((err) => {
        if (err.msg) {
          this.$message.error(err.msg);
        }
        this.isSubmit = false;
      }).finally(() => {
        this.isSubmit = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detelBtn {
  margin-left: 30px;
}
.fc {
  display: flex;
  align-items: center;
}
.mb10 {
  margin-bottom: 10px;
}
.mt6 {
  margin-top: 6px;
}
.bottom-btn-group {
  max-width: 1200px;
  margin-top: 80px;
  margin-left: 30px;
}
.addTimer {
  margin-top: 20px;
}
.fine-list {
  display: inline-block;
  width: 320px;
}
.colorRed {
  color: #ef3f46;
}
.inputInfo {
  width: 130px;
  margin: 0 10px;
}
</style>
