<template>
  <div class="pushTakeout distribution">
    <infoListBorder title="订单优先展示给代理配送团队" :required="1">
      <div class="push-content ml10">
        <el-input size='small' class="inputInfo" type='number' clearable v-model="pushDims[0].feeDimConds[0].pushNumbf"></el-input>
        分钟无代理团队骑手接单，则订单发往第三方配送平台
      </div>
    </infoListBorder>
    <infoListBorder title="优先全职骑手抢单设置">
      <div class="push-content ml10">
        <el-input size='small' class="inputInfo" type='number' clearable v-model="pushDims[1].feeDimConds[0].pushNumbf"></el-input>
        分钟无全职骑手接单，兼职骑手显示并可抢单
      </div>
    </infoListBorder>
    <infoListBorder title="骑手操作到店/送达设置">
      <div class="push-content-item">
        距离商户
        <el-input size='small' class="inputInfo" type="number" clearable v-model="pushDims[2].feeDimConds[0].pushNum"></el-input>
        <div>米</div>可操作“到店”按钮
      </div>
      <div class="push-content-item">
        距离用户
        <el-input size='small' class="inputInfo" type="number" clearable v-model="pushDims[3].feeDimConds[0].pushNum"></el-input>
        <div>米</div>可操作“已送达”按钮
      </div>
    </infoListBorder>
    <infoListBorder title="你是否需要用自己的骑手,去配送第三方订单(美团等)?" :required="1">
      <el-radio-group class="thirdParty-radio" v-model="thirdPartyOrder">
        <el-radio :label="1">已有骑手开始配送美团订单</el-radio>
        <el-radio :label="0">暂时无骑手接美团配送订单</el-radio>
      </el-radio-group>
    </infoListBorder>
    <div class="bottom-btn-group">
      <PrevReclick>
        <el-button size='small' @click="submitInfo(2)" type='primary' plain :loading="isSubmit" :disabled="isSubmit">返回上一页</el-button>
      </PrevReclick>
      <el-button size='small' @click="submitInfo(0)" type='primary' :loading="isSubmit" :disabled="isSubmit">提交设置</el-button>
    </div>
  </div>
</template>

<script>
import { deepClone } from '@base/utils';
import { $sp_psConfigAdd, $sp_psConfigUpdate, $sp_addSetting } from '@/api/setting';
import infoListBorder from './infoListBorder.vue';

export default {
  data() {
    return {
      isSubmit: false,
      pushDims: [// 订单推送设置

      ],
      thirdPartyOrder: null, // 三方订单设置
    };
  },
  components: {
    infoListBorder,
  },
  created() {
    const { pushDims } = this.$parent.fromSettinInfo.feeDimsTypeConfig;
    this.pushDims = deepClone(pushDims);
    this.thirdPartyOrder = !this.$parent.thirdPartyOrder ? 0 : 1;
  },
  methods: {
    // 获取外卖配送数据
    getWMArrayData(source, type) {
      const dataArray = this[source];
      const itemD = dataArray.find((item) => item.dimFrom === type);
      if (source === 'pushDims') {
        if (itemD && itemD.feeDimConds[0] && itemD.feeDimConds[0].pushNum != null && itemD.feeDimConds[0].pushNum !== '') {
          return itemD.feeDimConds[0].pushNumbf ? itemD.feeDimConds[0].pushNumbf : itemD.feeDimConds[0].pushNum;
        } else {
          return null;
        }
      } else {
        return itemD && itemD.feeDimConds.length ? itemD.feeDimConds : [];
      }
    },

    // 提交信息 进入下一环节
    submitInfo(val) {
      if (val) {
        this.$parent.setNextPage(val);
        return;
      }
      const methodLists = [$sp_psConfigAdd, $sp_psConfigUpdate];
      const methodsType = this.$parent.settingType === 'add' ? 0 : 1;
      const REG_time = /^([12][0-9]|30|[0-9])$/;
      const REG_distance = /^(0|[1-9][0-9]{0,3}|10000)$/;
      let errorInfo = '';
      if (!REG_time.test(this.pushDims[0].feeDimConds[0].pushNumbf)) {
        this.$message.error('请正确配置订单优先展示给代理配送团队，0~30正整数(含0和30)');
        return;
      }
      for (let i = 1; i < this.pushDims.length; i += 1) {
        const item = this.pushDims[i];
        if (i === 1 && item.feeDimConds[0] && item.feeDimConds[0].pushNumbf) {
          if (!REG_time.test(item.feeDimConds[0].pushNumbf)) {
            errorInfo = '请正确配置骑手优先抢单设置，0~30正整数(含0和30)';
            break;
          }
        }
        if (i > 1 && item.feeDimConds[0] && item.feeDimConds[0].pushNum) {
          if (!REG_distance.test(item.feeDimConds[0].pushNum)) {
            errorInfo = '请正确输入骑手到店/送达设置距离，0~10000正整数';
            break;
          }
        }
      }
      if (errorInfo) {
        this.$message.error(errorInfo);
        return;
      }
      if (this.thirdPartyOrder == null) {
        this.$message.error('请完善订单推送设置');
        return;
      }
      for (let j = 0; j < this.pushDims.length; j += 1) {
        const item = this.pushDims[j];
        if (j <= 1) {
          if (item) {
            const timeNum = item.feeDimConds[0].pushNumbf;
            item.feeDimConds[0].pushNum = timeNum === '' ? null : timeNum * 60;
          }
        } else {
          break;
        }
      }
      this.isSubmit = true;
      const pushData = this.pushDims.filter((item) => item.feeDimConds[0].pushNum != null);
      this.$parent.fromSettinInfo.thirdPartyOrder = this.thirdPartyOrder;
      this.$parent.fromSettinInfo.feeDimsTypeConfig.pushDims = pushData; // this.pushDims

      methodLists[methodsType]({ ...this.$parent.fromSettinInfo }).then(() => {
        $sp_addSetting({ val: this.$parent.fromSettinInfo.thirdPartyOrder }).then(() => {
          this.$parent.saveSuccess();
          this.$message({
            message: '设置成功',
            type: 'success',
          });
        }).catch((err) => {
          if (err.subCode) {
            this.$confirm(err.subCode, '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              center: true,
              customClass: 'k-el-message-box-width',
              confirmButtonClass: 'k-confirmBtn-width',
            }).then(() => {

            });
          }
        }).finally(() => {
          this.isSubmit = false;
        });
      }).catch((err) => {
        if (err.msg) {
          this.$message.error(err.msg);
        }
        this.isSubmit = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.distribution {
  .bottom-btn-group {
    max-width: 1200px;
    margin-top: 80px;
    margin-left: 30px;
  }
}

.inputInfo {
  width: 130px;
  margin: 0 10px;
}

// 三方配送设置
.thirdParty-radio {
  display: flex;
  flex-direction: column;
  .el-radio {
    margin: 20px 0 0 10px;
  }
}
// 推送设置
.push-content-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
  div {
    margin-right: 20px;
  }
}
</style>
