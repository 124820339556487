<template>
  <div class="steps">
    <div class="steps-item" v-for="(item,index) in imgLists" :key="index">
      <div v-if="index" style="margin:0 50px;">
        <img v-if="index<next" src="/img/setting/activeArrow.png" alt="">
        <img v-else src="/img/setting/arrow.png" alt="">
      </div>
      <div class="steps-item-box">
        <img v-if="index<next" :src="item.activeImg" alt="">
        <img v-else :src="item.defaultImg" alt="">
        <div :style="{color:index<next ? '#EF3F46' : '#999999'}">{{item.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    next: {
      type: Number || String,
      default: 1,
    },
    imgLists: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
.steps {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
  box-sizing: border-box;
  .steps-item {
    display: flex;
    align-items: center;
    .steps-item-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>
