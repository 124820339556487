<template>
  <div class="distribution">
    <infoListBorder title="配送计费设置" :required="1">
      <div class="temp">
        快捷模板
        <el-button v-for="(item, index) in tempButtonGroup" :key="item.id" :type='temBtnActive === index ? "primary" : ""'
          @click="checkTemp(index)" plain>{{item.name}}</el-button>
      </div>
      <div v-if="getWMArrayData('feeDims','Distance').length">
        <div class="distribution-fee-item" v-for="(item, index) in getWMArrayData('feeDims','Distance')" :key="index">
          <div>
            <span>起<span class="psDistanceStart">{{item.feeCondk[0] || 0}} km</span>至</span>
            <el-input size='small' class="inputInfo" clearable type="number" v-model="item.feeCondk[1]"
              @input="(val)=>{distanceChange(val,0,index)}">
            </el-input>km
          </div>
          <div>配送费 <el-input size='small' class="inputInfo" clearable type="number" v-model="item.feePrice"></el-input> 元 </div>
          <div>整段配送时长 <el-input size='small' class="inputInfo" clearable type="number" v-model="item.feeMinutes"></el-input> 分钟 </div>
          <div v-if="getTransferOrderData().openConfig">1段配送时长 
            <el-input size='small' class="inputInfo" clearable type="number"
            v-model="item.extendData.relayRiderDistributionTimes[0].minute"></el-input> 分钟 </div>
          <el-button type='text' icon="el-icon-delete" @click="deleteDataList(0,index)">删除</el-button>
        </div>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addDataList(0)">新增配送范围</el-button>
    </infoListBorder>
    <infoListBorder title="高峰/夜间特殊时间段加价设置">
      <div v-if="getWMArrayData('feeDims','Peak').length">
        <div class="peak-item-top">
          <div>开始时间</div>
          <div>结束时间</div>
          <div>加价金额</div>
        </div>
        <div v-for="(item, index) in getWMArrayData('feeDims','Peak')" :key="index" class="peak-item">
          <div class="peak-item-bottom">
            <div class="peak-bottom-box">
              <el-cascader size='small' :options="optionsStartTime" clearable placeholder="选择开始时间" separator=":" v-model="item.feeCondk[0]">
              </el-cascader>
            </div>
            <span class="as">至</span>
            <div class="peak-bottom-box">
              <el-cascader size='small' :options="optionsEndTime" clearable placeholder="选择结束时间" separator=":" v-model="item.feeCondk[1]">
              </el-cascader>
            </div>
            <div class="peak-bottom-box last-peak-item">
              <el-input clearable type="number" v-model="item.feePrice" size='small'></el-input> <span class="ml5">元</span>
            </div>
            <el-button type='text' icon="el-icon-delete" @click="deleteDataList(1,index)">删除</el-button>
          </div>
        </div>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addDataList(1)">新增时间段</el-button>
    </infoListBorder>
    <infoListBorder title="节假日特殊日期加价设置">
      <div v-if="getWMArrayData('feeDims','Holiday').length">
        <div class="holiday-item-top">
          <div>开始时间</div>
          <div>结束时间</div>
          <div>加价金额</div>
        </div>
        <div v-for="(item, index) in getWMArrayData('feeDims','Holiday')" :key="index" class="holiday-item">
          <div class="holiday-item-bottom">
            <div class="holiday-bottom-box">
              <el-date-picker size='small' type="datetime" placeholder="选择日期时间" value-format="timestamp" v-model="item.feeCondk[0]">
              </el-date-picker>
            </div>
            <span class="as">至</span>
            <div class="holiday-bottom-box">
              <el-date-picker size='small' type="datetime" placeholder="选择日期时间" value-format="timestamp" v-model="item.feeCondk[1]">
              </el-date-picker>
            </div>
            <div class="holiday-bottom-box last-holiday-item">
              <el-input size='small' clearable type="number" v-model="item.feePrice"></el-input><span class="ml5">元</span>
            </div>
            <el-button type='text' icon="el-icon-delete" @click="deleteDataList(2,index)">删除</el-button>
          </div>
        </div>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addDataList(2)">新增时间段</el-button>
    </infoListBorder>

    <infoListBorder title="转单设置" :required="2">
      开启接力送
      <el-radio-group class="radioGroup" v-model="getTransferOrderData().openConfig"
        @change="openRelayChange(getTransferOrderData().openConfig)">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="0">否</el-radio>
      </el-radio-group>
    </infoListBorder>

    <div class="bottom-btn-group">
      <el-button size='small' @click="submitInfo()" type='primary'>下一步，设置骑手收益</el-button>
    </div>

    <!-- 弹窗 -->
    <el-dialog title="提示" class="k-dialog" :visible.sync="bindingDialog" :close-on-click-modal="false" width="480px"
      :close-on-press-escape="false" center :show-close="false">
      <div class="binding-content">
        <p class="bingTishi">当前转单订单正在进行中，无法关闭此设置;订单结束后在关闭!!!</p>
        <div style="text-align:center;margin-top:20px">
          <el-button type="primary" @click="confirm">已知晓</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import { parseSeconds, deepClone } from '@base/utils';
import { $sg_tDistributionFeeTplDimTemp, $sg_agentRelayOrder } from '@/api/setting';
import infoListBorder from './infoListBorder.vue';

export default {
  data() {
    return {
      temBtnActive: -1, // 当前选择的模板
      tempButtonGroup: [
        { id: 1, name: '一线城市' },
        { id: 2, name: '新一线城市' },
        { id: 3, name: '二线城市' },
        { id: 4, name: '三线城市' },
        { id: 5, name: '四线城市' },
        { id: 6, name: '县城' },
      ],
      optionsStartTime: [], // 时间段开始
      optionsEndTime: [], // 时间段结束
      setInfoType: '0', // 修改计费设置的类型
      feeDims: [// 外卖计费设置

      ],
      pushDims: [// 订单推送设置

      ],
      earningsConfigList: '',
      bindingDialog: false,
    };
  },
  components: {
    infoListBorder,
  },
  created() {
    const timeLists = this.$parent.datePickerData();
    this.optionsStartTime = timeLists;
    this.optionsEndTime = timeLists;
    const { feeDims, pushDims } = this.$parent.fromSettinInfo.feeDimsTypeConfig;
    this.feeDims = deepClone(feeDims);
    this.pushDims = deepClone(pushDims);
    const { earningsConfigList } = this.$parent.fromSettinInfo.earningsConfig;
    this.earningsConfigList = deepClone(earningsConfigList);
  },
  methods: {
    // 获取外卖配送数据
    getWMArrayData(source, type) {
      const dataArray = this[source];
      const itemD = dataArray.find((item) => item.dimFrom === type);
      if (source === 'pushDims') {
        if (itemD && itemD.feeDimConds[0] && itemD.feeDimConds[0].pushNum != null && itemD.feeDimConds[0].pushNum !== '') {
          return itemD.feeDimConds[0].pushNumbf ? itemD.feeDimConds[0].pushNumbf : itemD.feeDimConds[0].pushNum;
        } else {
          return null;
        }
      } else {
        return itemD && itemD.feeDimConds.length ? itemD.feeDimConds : [];
      }
    },
    // 距离配置输入框变化
    distanceChange(val, type, index) {
      const arrayData = this.feeDims[type].feeDimConds;
      if (arrayData[index + 1]) {
        arrayData[index + 1].feeCondk[0] = val;
      }
    },
    // 删除表单数据
    deleteDataList(type, index) {
      this.$confirm('此操作将删除该条设置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.feeDims[type].feeDimConds.splice(index, 1);
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
      });
    },
    // 添加数据
    addDataList(type) {
      if (type === 0) {
        const len = this.feeDims[type].feeDimConds.length;
        const dataArray = this.feeDims[type].feeDimConds;
        let feeCondk = [];
        feeCondk = [dataArray[len - 1] ? dataArray[len - 1].feeCondk[1] : 0, null];
        if (len && this.checkDistance()) {
          return;
        }
        if (len && Number(dataArray[len - 1].feeCondk[1]) === 10) {
          this.$message.error('最后一条结束距离为10km,不能继续新增');
          return;
        }
        this.feeDims[type].feeDimConds.push({
          feeCondk,
          feeCond: null,
          feePrice: null,
          feeMinutes: null,
          extendData: {
            relayRiderDistributionTimes: [{
              level: 1,
              minute: '',
            }],
          },
        });
      }
      if (type === 1) {
        const len = this.feeDims[type].feeDimConds.length;
        if (len && this.checkPeak()) {
          return;
        }
        this.feeDims[type].feeDimConds.push({
          feeCondk: [],
          feeCond: null,
          feePrice: null,
        });
      }
      if (type === 2) {
        const len = this.feeDims[type].feeDimConds.length;
        if (len && this.checkHoliday()) {
          return;
        }
        this.feeDims[type].feeDimConds.push({
          feeCondk: ['', ''],
          feeCond: null,
          feePrice: null,
        });
      }
    },
    // 选择快捷模板
    checkTemp(index) {
      this.temBtnActive = index;
      $sg_tDistributionFeeTplDimTemp(index + 1).then((res) => {
        this.feeDims[0].feeDimConds = res.feeDims[0].feeDimConds.map((item) => {
          // const list = item.feeCond.match(/\((\S*)]/)[1];
          const list = item.feeCond[0] === '[' ? item.feeCond.match(/\[(\S*)]/) : item.feeCond.match(/\((\S*)]/);
          return {
            feeCondk: list[1].split('~'),
            feeCond: null,
            feePrice: item.feePrice,
            feeMinutes: item.feeMinutes,
            extendData: item.extendData ? item.extendData : { relayRiderDistributionTimes: [{ level: 1, minute: '' }] },
          };
        });
      });
    },
    // 获取转单设置数据
    getTransferOrderData() {
      return this.earningsConfigList.find((item) => item.calculateType === 'OpenRelay');
    },
    // 接力订单开关 选择否(查询是否有接力订单正在进行)
    openRelayChange(val) {
      if (!val) {
        $sg_agentRelayOrder().then((res) => {
          this.bindingDialog = res;
        });
      }
    },
    // 已知晓确认框
    confirm() {
      this.bindingDialog = false;
      this.getTransferOrderData().openConfig = 1;
    },
    // 提交信息 进入下一环节
    submitInfo() {
      // const REG_time = /^([12][0-9]|30|[0-9])$/;
      // const REG_distance = /^(0|[1-9][0-9]{0,3}|10000)$/;
      // let errorInfo = '';
      if (this.checkDistance(true)) {
        return;
      }
      if (this.feeDims[1] && this.feeDims[1].feeDimConds) {
        if (this.checkPeak()) {
          return;
        }
      }
      if (this.feeDims[2] && this.feeDims[2].feeDimConds) {
        if (this.checkHoliday()) {
          return;
        }
      }
      this.feeDims[0].feeDimConds.forEach((item, index) => {
        item.feeCond = `${index === 0 ? '[' : '('}${item.feeCondk.join('~')}]`;
      });
      if (this.feeDims[1].feeDimConds.length) {
        this.feeDims[1].feeDimConds.forEach((item, index) => {
          item.feeCond = `${index === 0 ? '[' : '('}${parseSeconds(item.feeCondk[0][0] + item.feeCondk[0][1])}~${parseSeconds(item.feeCondk[1][0] + item.feeCondk[1][1])}]`;
        });
      }
      if (this.feeDims[2].feeDimConds.length) {
        this.feeDims[2].feeDimConds.forEach((item) => {
          item.feeCond = `[${item.feeCondk[0] / 1000}~${item.feeCondk[1] / 1000}]`;
        });
      }
      const feeDate = this.feeDims.filter((item) => item.feeDimConds.length);
      this.$parent.fromSettinInfo.feeDimsTypeConfig.feeDims = feeDate;
      this.$parent.fromSettinInfo.earningsConfig.earningsConfigList = this.earningsConfigList;
      this.$parent.setNextPage(2);
    },
    // 校验配送计费设置 
    checkDistance(submit = false) {
      const len = this.feeDims[0].feeDimConds.length;
      let errorInfo = '';
      if (submit && !len) {
        errorInfo = '配送计费为必填项';
      }
      const REG_DISTANCE = /^([0-9]|10)$/;
      const REG_PRICE = /^(\d{1,4}(\.[0-9]{1})?)$|^9999$/;
      const REG_time = /^(0|[1-9][0-9]{0,3})$/;
      for (let i = 0; i < len; i += 1) {
        const item = this.feeDims[0].feeDimConds[i];
        if (!REG_DISTANCE.test(item.feeCondk[1])) {
          errorInfo = '请正确输入公里数，0~10正整数';
          break;
        }
        if (Number(item.feeCondk[1]) <= Number(item.feeCondk[0])) {
          errorInfo = '结束公里数需大于起始公里数';
          break;
        }
        if (i > 0 && +item.feeCondk[0] !== +this.feeDims[0].feeDimConds[i - 1].feeCondk[1]) {
          errorInfo = '配送计费开始距离应该等于上一级结束距离';
          break;
        }
        if (!REG_PRICE.test(item.feePrice)) {
          errorInfo = '请正确输入配送费，0~9999(可保留一位小数)';
          break;
        }
        if (!REG_time.test(item.feeMinutes)) {
          errorInfo = '请正确输入配送时长，0~9999正整数';
          break;
        }
        if (this.getTransferOrderData().openConfig && !REG_time.test(item.extendData.relayRiderDistributionTimes[0].minute)) {
          errorInfo = '请正确输入配送时长，0~9999正整数';
          break;
        } else if (!this.getTransferOrderData().openConfig) {
          item.extendData = null;
        }
        if (this.getTransferOrderData().openConfig && +item.extendData.relayRiderDistributionTimes[0].minute > +item.feeMinutes) {
          errorInfo = '1段配送时长不能大于整段配送时长';
          break;
        }
        // 提交是需验证最后一条数据 公里数最大是否为10km
        if (submit && i === len - 1 && Number(item.feeCondk[1]) !== 10) {
          errorInfo = '最后一条结束公里数必须为10km';
          break;
        }
      }
      if (errorInfo) {
        this.$message.error(errorInfo);
      }
      return errorInfo !== '';
    },
    // 校验时间段加价设置
    checkPeak() {
      let errorInfo = '';
      const REG_PRICE = /^(\d{1,4}(\.[0-9]{1})?)$|^9999$/;
      const dataArray = this.feeDims[1].feeDimConds;
      for (let i = 0; i < dataArray.length; i += 1) {
        const item = dataArray[i];
        if (!item.feeCondk[0] || !(item.feeCondk[0]).join(',') || !item.feeCondk[1] || !(item.feeCondk[1]).join(',')) {
          errorInfo = '请选择时间段开始或结束时间';
          break;
        }
        if ((item.feeCondk[1][0] + item.feeCondk[1][1]) <= (item.feeCondk[0][0] + item.feeCondk[0][1])) {
          errorInfo = '特殊时间段结束时间需大于起始时间';
          break;
        }
        if (!REG_PRICE.test(item.feePrice)) {
          errorInfo = '请正确输入特殊时间段加价金额，0~9999(可保留一位小数)';
          break;
        }
        for (let j = i + 1; j < dataArray.length; j += 1) {
          const itemc = dataArray[j];
          if (!itemc.feeCondk[1] || !itemc.feeCondk[0]) {
            errorInfo = '请选择特殊时间段起始或结束时间';
            break;
          }
          if (itemc.feeCondk[0][0] + itemc.feeCondk[0][1] <= item.feeCondk[1][0] + item.feeCondk[1][1]) {
            errorInfo = '特殊时间段的开始时间需大于上一时间段的结束时间';
            break;
          }
          if ((itemc.feeCondk).join(',') === (item.feeCondk).join(',')) {
            errorInfo = '特殊时间段不能出现相同时间段';
            break;
          }
        }
      }
      if (errorInfo) {
        this.$message.error(errorInfo);
      }
      return errorInfo !== '';
    },
    // 校验节假日加价设置
    checkHoliday() {
      let errorInfo = '';
      const REG_PRICE = /^(\d{1,4}(\.[0-9]{1})?)$|^9999$/;
      for (let i = 0; i < this.feeDims[2].feeDimConds.length; i += 1) {
        const item = this.feeDims[2].feeDimConds[i];
        if (!item.feeCondk[0] || !item.feeCondk[1] || item.feeCondk.length < 2) {
          errorInfo = '请选择节假日日期开始或结束时间';
          break;
        }
        if (item.feeCondk[1] < item.feeCondk[0]) {
          errorInfo = '节假日日期结束时间需大于起始时间';
          break;
        }
        if (!REG_PRICE.test(item.feePrice)) {
          errorInfo = '请正确输入节假日日期加价金额，0~9999(可保留一位小数)';
          break;
        }
        for (let j = i + 1; j < this.feeDims[2].feeDimConds.length; j += 1) {
          const itemc = this.feeDims[2].feeDimConds[j];
          if ((itemc.feeCondk).join(',') === (item.feeCondk).join(',')) {
            errorInfo = '节假日日期不能出现相同时间段';
            break;
          }
        }
      }
      if (errorInfo) {
        this.$message.error(errorInfo);
      }
      return errorInfo !== '';
    },
  },
};
</script>

<style lang="scss" scoped>
.addTimer {
  margin-top: 10px;
}
.distribution {
  .distribution-fee-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    > div {
      text-align: left;
      margin-right: 30px;
    }
    .psDistanceStart {
      display: inline-block;
      min-width: 70px;
      text-align: center;
      margin: 0 6px;
    }
  }
  .peak-item-top {
    display: flex;
    margin: 20px 0;
    div {
      flex: 0 0 160px;
      margin-right: 25px;
      &:nth-child(2) {
        margin: 0;
      }
    }
  }
  .peak-item-bottom {
    display: flex;
    align-items: center;
    .peak-bottom-box {
      width: 150px;
      margin-right: 10px;
    }
    .as {
      display: inline-block;
      margin-right: 10px;
    }
  }
  .last-peak-item {
    width: 200px;
    display: flex;
    align-items: center;
  }
  // 节假日
  .holiday-item-top {
    display: flex;
    margin: 20px 0;
    div {
      flex: 0 0 230px;
      margin-right: 25px;
      &:nth-child(2) {
        margin: 0;
      }
    }
  }
  .holiday-item-bottom {
    display: flex;
    align-items: center;
    .holiday-bottom-box {
      width: 220px;
      margin-right: 10px;
    }
    .as {
      display: inline-block;
      margin-right: 10px;
    }
  }
  .last-holiday-item {
    width: 150px !important;
    display: flex;
    align-items: center;
  }
  .bottom-btn-group {
    max-width: 1200px;
    margin-top: 50px;
    margin-left: 30px;
  }
}
.inputInfo {
  width: 130px;
  margin: 0 10px;
}

</style>
