<template>
  <div class="notCreated">
    <div class="notCreated-card">
      <img src="/img/setting/notCreate.png" alt="">
      <div class="tips">
        <slot name="noCreat"></slot>
      </div>
      <el-button @click="setting" type='primary' v-if="btnIsShow">立即设置</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    btnIsShow: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    setting() {
      this.$emit('setting');
    },
  },
};
</script>

<style lang="scss" scoped>
.notCreated {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .notCreated-card {
    width: 268px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .tips {
      margin: 20px 0;
      color: #999;
    }
    .el-button {
      width: 100%;
    }
  }
}
</style>
