<template>
  <div class="meituanDelivery">
    <el-button type="primary" @click="openAddRuleDialog">新增</el-button>
    <el-table :data="tableData" style="width: 100%;margin-top: 20px;" border>
      <el-table-column prop="rule_name" label="名称" align="center"></el-table-column>
      <el-table-column prop="price" label="规则" align="center">
        <template slot-scope="scope">
          <span>每单配送费{{scope.row.price}}元</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" @click="openRelationBusiness(scope.row)">关联商家</el-button>
          <el-button type="text" @click="delRule(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <!-- <el-pagination
      class="mt10" 
      background 
      :page-count="totalPage" 
      :current-page.sync="currentPage" 
      @current-change="pageChange">
    </el-pagination> -->

    <!-- 添加规则弹窗 -->
    <el-dialog
      title="添加规则"
      :visible.sync="addRuleDialog"
      center
      :show-close="false"
      :close-on-click-modal="false"
      width="600px">
      <div class="item">
        <span>规则名称：</span>
        <el-input v-model="ruleForm.rule_name" placeholder="请输入规则名称" style="width: 240px;"></el-input>
      </div>
      <div class="item">
        <span>每单配送费：</span>
        <el-input v-model="ruleForm.price" placeholder="请输入金额" style="width: 240px;margin-right:5px"></el-input>元
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="addRuleDialog = false">取消</el-button>
        <el-button type="primary" @click="addRule">确定</el-button>
      </span>
    </el-dialog>

    <!-- 关联商家弹窗 -->
    <el-dialog
      title="关联商家"
      :visible.sync="relationBusiness"
      center
      :show-close="false"
      :close-on-click-modal="false"
      width="700px">
      <div class="search-shop-box">
        <el-input v-model="shopName" placeholder="商户名称/电话" style="width: 200px;margin-right: 10px;" clearable></el-input>
        <el-button type="primary" @click="searchShop">搜索</el-button>
      </div>
      <div class="table-box">
        <el-table :data="shopData" ref="shopTable" style="width: 100%;margin-top: 20px;"
          border row-key="shopId" @selection-change="selectShop">
          <el-table-column type="selection" width="55" reserve-selection></el-table-column>
          <el-table-column prop="shopName" label="商户名称" align="center"></el-table-column>
          <el-table-column prop="tel" label="店铺账号" align="center"></el-table-column>
      </el-table>
      <el-pagination
        class="mt10" 
        background 
        :page-count="totalPageShop" 
        :current-page.sync="pageCurrentShop" 
        @current-change="getShopList">
      </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="relationBusiness = false">取消</el-button>
        <el-button type="primary" @click="doRuleShop">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { 
  $sg_ruleList, 
  $sp_doRule, 
  $sp_delRule, 
  $sp_doRuleShop,
  $sg_getRuleShopList,
} from '@/api/setting';
import { $agent_shopPage } from '@/api/agentAll';

export default {
  data() {
    return {
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      ruleForm: {
        rule_name: '',
        price: '',
      },
      rule_id: '',
      addRuleDialog: false,
      relationBusiness: false,
      shopName: '',
      totalPageShop: 1,
      pageCurrentShop: 1,
      shopData: [],
      selectShopData: [],
    };
  },
  created() {
    this.ruleList();
  },
  methods: {
    // 获取规则列表
    ruleList() {
      $sg_ruleList().then((res) => {
        this.tableData = res;
      });
    },
    // 新增
    openAddRuleDialog() {
      this.rule_id = '';
      this.ruleForm = {
        rule_name: '',
        price: '',
      };
      this.addRuleDialog = true;
    },
    // 编辑
    edit(row) {
      this.rule_id = row.id;
      this.ruleForm.rule_name = row.rule_name;
      this.ruleForm.price = row.price;
      this.addRuleDialog = true;
    },
    // 添加或者修改规则
    async addRule() {
      await this.$validator({
        ...this.ruleForm,
      }, [
        'rule_name/require/规则名称',
        'price/require/金额',
      ]);
      if (+this.ruleForm.price <= 0) {
        this.$message.error('金额必须大于0');
        return;
      }
      const params = {
        ...this.ruleForm,
      };
      if (this.rule_id) {
        params.rule_id = this.rule_id;
      }
      $sp_doRule(params).then(() => {
        this.addRuleDialog = false;
        this.ruleList();
      });
    },
    // 打开关联商家弹窗
    async openRelationBusiness(row) {
      this.rule_id = row.id;
      this.shopName = '';
      if (this.selectShopData.length > 0) {
        this.selectShopData = [];
        this.$refs.shopTable.clearSelection();
      }
      this.relationBusiness = true;
      const params = {
        rule_id: row.id,
      };
      // 请求已关联商户
      await $sg_getRuleShopList(params).then((res) => {
        // 选中已关联的商户
        const merchant_id_arr = res;
        merchant_id_arr.forEach((val) => {
          this.$refs.shopTable.toggleRowSelection({
            shopId: val.merchant_id,
          }, true);
        });
      });
      // 请求商户列表
      this.pageCurrentShop = 1;
      await this.getShopList();
    },
    // 搜索商户
    searchShop() {
      this.pageCurrentShop = 1;
      this.getShopList();
    },
    // 请求商户列表
    getShopList() {
      const params = {
        shopName: this.shopName,
        pageCurrent: this.pageCurrentShop,
        pageSize: 10,
      };
      // 请求商户列表
      return $agent_shopPage(params).then((res) => {
        this.shopData = res.records;
        this.totalPageShop = res.pages;
      });
    },
    // 选择商家
    selectShop(e) {
      this.selectShopData = e;
    },
    // 关联商家
    doRuleShop() {
      if (!this.selectShopData || this.selectShopData.length === 0) {
        this.$message.error('请选择关联商家');
        return;
      }
      const selectShopData = [];
      this.selectShopData.forEach((val) => {
        selectShopData.push(val.shopId);
      });
      const merchant_id_arr = selectShopData.join(',');
      const params = {
        rule_id: this.rule_id,
        merchant_id_arr,
      };
      $sp_doRuleShop(params).then(() => {
        this.relationBusiness = false;
        this.ruleList();
      });
    },
    // 删除规则
    delRule(row) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        closeOnClickModal: false,
        center: true,
      }).then(() => {
        const params = {
          rule_id: row.id,
        };
        $sp_delRule(params).then(() => {
          this.ruleList();
        });
      }).catch(() => {});
    },
    // 翻页
    pageChange() {},
  },
};
</script>
<style lang="scss" scoped>
.meituanDelivery {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  .item {
    width: 100%;
    margin-top: 20px;
    & > span {
      width: 100px;
      display: inline-block;
      text-align: right;
    }
  }
  .search-shop-box {
    margin-bottom: 20px;
  }
  .checkbox-item {
    margin: 10px;
  }
}
</style>
