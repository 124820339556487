<template>
  <div class="setting distribution" v-loading="loading" element-loading-text="数据获取中..." element-loading-customClass='k-ele-loading'>
    <notCreated @setting="edit('add')" v-if="isCreated">
      <div slot="noCreat">未进行跑腿配送业务设置</div>
    </notCreated>
    <!-- 查看 -->
    <div class="look" v-if="isLook">
      <div class="isRequired">注：所有设置只是针对您自建的配送团队骑手有效，其他代理商或第三方其他配送骑手，将不会执行以下设置，将根据其他代理商设置或第三方配送规则执行。</div>
      <el-button v-if="isLook" type='primary' class="fixed-btn" @click="edit('edit')">修改设置</el-button>
      <p class="level1Titel" v-if="rootAgentType">跑腿配送设置</p>
      <p v-if="rootAgentType && !fromSettinInfo.runErrandsConfig.feeConfigList.length" class="noset">暂未配置</p>
      <template v-if="rootAgentType && fromSettinInfo.runErrandsConfig.feeConfigList.length">
        <infoListBorder class="showDetailInfo" title="配送距离计费设置(帮买/帮取/帮送)" :required="1">
          <div v-if="getPTArrayData('StartDistance').length">
            <p v-for="(itemc,indexc) in getPTArrayData('StartDistance')" :key="indexc">
              起<span>{{itemc.scopek[0]}}km</span>至<span>{{itemc.scopek[1]}}km</span>
              <span style="font-weight:400;width:60px;text-align:left;margin:0;">{{!indexc?'起步价':'加价金额'}}</span>
              <span>{{itemc.price}}元</span>
            </p>
            <p style="color:#f63d2a;margin-top:10px;">超出距离每km自动加 "最后一个梯度加价金额+0.5" 元</p>
          </div>
          <p v-else>暂未配置</p>
        </infoListBorder>
        <infoListBorder class="showDetailInfo" title="重量设置(帮买/帮取/帮送)" :required="1">
          <div v-if="getPTArrayData('Weight').length">
            <p v-for="(itemc,indexc) in getPTArrayData('Weight')" :key="indexc">
              起<span>{{itemc.scopek[0]}}kg</span>至<span>{{itemc.scopek[1]}}kg</span>加价金额<span>{{itemc.price}}元</span>
            </p>
          </div>
          <p v-else>暂未配置</p>
        </infoListBorder>
        <infoListBorder class="tabelDetailInfo" title="特殊时间段加价设置(帮买/帮取/帮送/帮排队/全能帮手)">
          <div v-if="getPTArrayData('Peak').length">
            <p><span>开始时间</span><span>结束时间</span><span>加价金额</span></p>
            <p v-for="(itemc,indexc) in getPTArrayData('Peak')" :key="indexc">
              <span>{{formattingTime(itemc.scopek[0][0]+itemc.scopek[0][1])}}</span>
              <span>{{formattingTime(itemc.scopek[1][0]+itemc.scopek[1][1])}}</span>
              <span>{{itemc.price}}元</span>
            </p>
          </div>
          <p v-else>暂未配置</p>
        </infoListBorder>
        <infoListBorder class="showDetailInfo" title="帮排队基础计费设置" :required="1">
          <p v-if="getPTArrayData('QueueUpBasics')[0].price==null || getPTArrayData('QueueUpBasics')[0].price===''">暂未配置</p>
          <p v-else>
            起步价(≤60min)<span>{{getPTArrayData('QueueUpBasics')[0].price}}元</span>
            超出后时间<span>{{getPTArrayData('QueueUpBasics')[0].extraPrice}}元/30min</span>
          </p>
        </infoListBorder>
        <infoListBorder class="showDetailInfo" title="全能帮手基础计费设置" :required="1">
          <p v-for="(itemc,indexc) in getPTArrayData('VersatileBasics')" :key="indexc">
            <span class="center-width">{{helper[indexc]}}</span>
            <span class="price" v-if="itemc.price==null || itemc.price===''">暂未配置</span>
            <span class="price" v-else>{{itemc.price}}元/h</span>
          </p>
        </infoListBorder>
      </template>

      <p class="level1Titel">骑手收益设置</p>
      <p v-if="!fromSettinInfo.earningsConfig.earningsConfigList.length" class="noset">暂未配置</p>
      <infoListBorder class="showDetailInfo" title="按比例计算骑手收益" scendTitle="根据每单总配送费设置骑手收益比例">
        <p v-if="!getSYArrayData('ScaleIncome')[0].earningsScale">暂未配置</p>
        <p v-else>每单骑手从配送费当中抽取<span>{{getSYArrayData('ScaleIncome')[0].earningsScale}}%</span>抽佣比例不能大于100%</p>
      </infoListBorder>
      <infoListBorder class="showDetailInfo" title="额外奖励骑手配送费" scendTitle="奖励为累计添加，从代理商账户余额扣除">
        <div v-if="getSYArrayData('AwardIncome').length">
          <p v-for="(itemc,indexc) in getSYArrayData('AwardIncome')" :key="indexc">
            每日完成配送<span>{{itemc.scope}}单</span>奖励<span>{{itemc.earningsPrice}}元</span>
          </p>
        </div>
        <p v-else>暂未配置</p>
      </infoListBorder>
      <infoListBorder class="showDetailInfo" title="骑手罚款机制" scendTitle="骑手每天最多允许放弃3单" :required="1">
        <p v-for="(itemc,indexc) in getSYArrayData('CancelPunitive')" :key="indexc">
          <span class="langLabel" v-if="indexc<=2">当日第{{tiems[itemc.scope]}}次放单,扣除当前放单订单收益金额的</span>
          <span v-if="itemc.earningsScale==null || itemc.earningsScale===''">暂未配置</span>
          <span v-if="indexc<=2">{{itemc.earningsScale}}%</span>
        </p>
      </infoListBorder>
      <p class="level1Titel">订单推送设置</p>
      <infoListBorder class="showDetailInfo" title="优先全职骑手抢单设置" :required="0">
        <p v-if="!getTSArrayData('Minutes')[0].pushNum">暂未配置</p>
        <p v-else><span>{{getTSArrayData('Minutes')[0].pushNum}}分钟</span>无全职骑手接单，兼职骑手显示并可抢单</p>
      </infoListBorder>
    </div>
    <!-- 修改 -->
    <div v-if="isEdit">
      <div class="set-header">
        <steps :next="next" :imgLists="imgLists" />
      </div>
      <div class='settingBox'>
        <!-- 非一级代理隐藏计费设置，骑手收益和订单推送设置均需要展示出来 -->
        <errandDistribution ref="errandDistribution" v-if="rootAgentType" v-show='next === 1' />
        <errandSetting ref="errandSetting" v-show='next === 2' />
        <errandPushTakeout ref="errandPushTakeout" v-show="next === 3" />
      </div>
    </div>
  </div>
</template>

<script>
import { $sg_psConfigGet, $sg_getAgentInfo } from '@/api/setting';
import {
  getSeconds, parseSeconds,
  formatDate, deepClone, getJwtTokenData,
} from '@base/utils';
import { getUserToken } from '@/global/tokenManager';
import notCreated from './components/setting/notCreated.vue';
import steps from './components/setting/steps.vue';
import infoListBorder from './components/setting/infoListBorder.vue';
import errandDistribution from './components/setting/errandDistribution.vue';
import errandSetting from './components/setting/errandSetting.vue';
import errandPushTakeout from './components/setting/errandPushTakeout.vue';

export default {
  components: {
    notCreated,
    steps,
    errandDistribution,
    infoListBorder,
    errandSetting,
    errandPushTakeout,
  },
  data() {
    return {
      loading: false,
      isCreated: false, // 暂无设置信息
      isEdit: false, // 编辑
      isLook: false, // 查看
      settingType: null, // 设置类型 add 新增 edit 编辑
      next: null, // 设置进度
      rootAgentType: 0, // 代理等级 不等于0 为一级代理 （非一级代理不能配置计费设置）
      fromSettinInfo: { // 所有的信息
        runErrandsConfig: { // 跑腿配送设置
          feeConfigList: [
            {
              calculateType: 'StartDistance', // 配送距离计费设置
              configCondList: [], // scope:'0~5' 距离  price 金额 serial 序号
            },
            {
              calculateType: 'Weight', // 重量设置(帮买/帮取/帮送)
              configCondList: [],
            },
            {
              calculateType: 'Peak', // 特殊时间段加价设置(帮买/帮取/帮送/帮排队/全能帮手)
              configCondList: [],
            },
            {
              calculateType: 'QueueUpBasics', // 帮排队基础计费设置
              configCondList: [
                {
                  scope: 60, // 范围 基本服务时间 分钟
                  price: null, // 单价
                  minutes: 30, // 超出时间分钟数
                  extraPrice: null, // 超出单价
                  serial: 1,
                },
              ],
            },
            {
              calculateType: 'VersatileBasics', // 全能帮手基础计费设置
              configCondList: [
                {
                  ruleType: 'Versatile',
                  price: null,
                  serial: 1,
                },
                {
                  ruleType: 'Carrying',
                  price: null,
                  serial: 2,
                },
                {
                  ruleType: 'Hourly',
                  price: null,
                  serial: 3,
                },
                {
                  ruleType: 'VisitCook',
                  price: null,
                  serial: 4,
                },
                {
                  ruleType: 'Pet',
                  price: null,
                  serial: 5,
                },
                {
                  ruleType: 'DistributeFlyer',
                  price: null,
                  serial: 6,
                },
                {
                  ruleType: 'Activities',
                  price: null,
                  serial: 7,
                },
                {
                  ruleType: 'RemoteCharge',
                  price: null,
                  serial: 8,
                },
              ],
            },
          ],
          pushConfigList: [ // 订单推送设置
            {
              calculateType: 'Minutes', // 抢单设置
              applyType: 0,
              configCondList: [
                {
                  serial: 1,
                  earningsPrice: 0,
                  earningsScale: 0,
                  extraPrice: 0,
                  id: null,
                  minutes: 0,
                  price: 0,
                  ruleType: 'Minutes',
                  runErrandsConfigId: null,
                  scope: '',
                  pushNum: null,
                },
              ],
            },
          ],
        },
        earningsConfig: { // 骑手收益设置
          earningsConfigList: [
            {
              calculateType: 'ScaleIncome', // 按比例计算骑手收益
              configCondList: [
                {
                  earningsScale: null,
                  serial: 1,
                },
              ],
            },
            {
              calculateType: 'AwardIncome', // 奖励
              configCondList: [], // {serial: 1, // 序号 scope: null, // 单earningsPrice: null, // 元 }
            },
            {
              calculateType: 'CancelPunitive', // 惩罚
              configCondList: [ // {serial: 1, // 序号 scope: 1, // 第几次放单 earningsScale: null, // 元  ruleType: 'CancelOrder', // 单}
                {
                  earningsScale: null,
                  serial: 1,
                  scope: 1,
                  ruleType: 'CancelOrder',
                },
                {
                  earningsScale: null,
                  serial: 2,
                  scope: 2,
                  ruleType: 'CancelOrder',
                },
                {
                  earningsScale: null,
                  serial: 3,
                  scope: 3,
                  ruleType: 'CancelOrder',
                },
              ],
            },
          ],
        },

        businessType: 2,

      },
      imgLists: [
        { title: '计费设置', activeImg: '/img/setting/charging.png', defaultImg: '/img/setting/charging.png' },
        { title: '骑手收益设置', activeImg: '/img/setting/activeTakeAway.png', defaultImg: '/img/setting/takeAway.png' },
        { title: '订单推送设置', activeImg: '/img/setting/activePush.png', defaultImg: '/img/setting/push.png' },
      ],
      tiems: ['', '一', '二', '三'],
      helper: ['全能帮手服务费', '搬运物品服务费', '小时工服务费', '上门做饭服务费', '照顾宠物服务费', '派发传单服务费', '活动招募服务费', '异地代办服务费'],
    };
  },
  async mounted() {
    const agentSid = getJwtTokenData(getUserToken()).agent_sid;
    await $sg_getAgentInfo(agentSid).then((d) => {
      this.rootAgentType = d.rootAgentType;
      if (!this.rootAgentType) {
        this.imgLists = [
          { title: '骑手收益设置', activeImg: '/img/setting/activeTakeAway.png', defaultImg: '/img/setting/takeAway.png' },
          { title: '订单推送设置', activeImg: '/img/setting/activePush.png', defaultImg: '/img/setting/push.png' },
        ];
        this.fromSettinInfo.runErrandsConfig.feeConfigList = []; // 下级代理不能设置计费设置
      }
    });
    this.getList();
  },
  methods: {
    // 获取跑腿设置数据
    getPTArrayData(type) {
      const dataArray = this.fromSettinInfo.runErrandsConfig.feeConfigList;
      const itemD = dataArray.find((item) => item.calculateType === type);
      return itemD && itemD.configCondList.length ? itemD.configCondList : [];
    },
    // 获取骑手收益设置数据
    getSYArrayData(type) {
      const dataArray = this.fromSettinInfo.earningsConfig.earningsConfigList;
      const itemD = dataArray.find((item) => item.calculateType === type);
      return itemD && itemD.configCondList.length ? itemD.configCondList : [];
    },
    // 获取订单推送设置设置数据
    getTSArrayData(type) {
      const dataArray = this.fromSettinInfo.runErrandsConfig.pushConfigList;
      const itemD = dataArray.find((item) => item.calculateType === type);
      return itemD && itemD.configCondList.length ? itemD.configCondList : [];
    },
    // 秒转日期
    formattingSecondToDate(seconds) {
      return formatDate(seconds / 1000, 'yyyy-MM-dd HH:mm:ss');
    },
    // 秒数转时分
    formattingTime(seconds) {
      return parseSeconds(seconds);
    },
    // 设置即将设置的环节 （1 计费设置 2 骑手收益设置）
    setNextPage(page) {
      this.next = page;
    },
    getList(getInfoType = null) {
      if (getInfoType) {
        this.fromSettinInfo = this.$options.data().fromSettinInfo;
      }
      this.loading = true;
      $sg_psConfigGet({ businessType: 2 }).then((res) => {
        const {
          runErrandsConfig: { feeConfigList },
          earningsConfig: { earningsConfigList },
        } = res;
        const runPushConfigList = res.runPushConfig ? res.runPushConfig.earningsConfigList : [];
        this.setShowStatus(false, false, true);
        // 跑腿配送设置
        if (feeConfigList.length) {
          for (let i = 0; i < feeConfigList.length; i += 1) {
            const itemArray = feeConfigList[i].configCondList;
            const type = feeConfigList[i].calculateType;
            if (itemArray && itemArray.length) {
              for (let j = 0; j < itemArray.length; j += 1) {
                const scopeArray = itemArray[j].scope.split('~');
                if (type === 'StartDistance' || type === 'Weight') {
                  itemArray[j].scopek = scopeArray;
                }
                if (type === 'Peak') {
                  const startMinute = getSeconds(scopeArray[0]);
                  const startSeconds = startMinute % 3600;
                  const endMinute = getSeconds(scopeArray[1]);
                  const endSeconds = endMinute % 3600;
                  itemArray[j].scopek = [[startMinute - startSeconds, startSeconds], [endMinute - endSeconds, endSeconds]];
                }
              }
            }
          }
        }
        if (feeConfigList.length) {
          const bfArray = deepClone(this.fromSettinInfo.runErrandsConfig.feeConfigList);
          const peakIndex = feeConfigList.findIndex((item) => item.calculateType === 'Peak');
          if (peakIndex === -1) {
            this.fromSettinInfo.runErrandsConfig.feeConfigList[2].configCondList = [];
          }
          feeConfigList.forEach((item) => {
            const fIndex = bfArray.findIndex((itemc) => itemc.calculateType === item.calculateType);
            if (fIndex > -1) {
              this.fromSettinInfo.runErrandsConfig.feeConfigList.splice(fIndex, 1, item);
            }
          });
        }
        if (earningsConfigList && earningsConfigList.length) {
          const bfArray = deepClone(this.fromSettinInfo.earningsConfig.earningsConfigList);
          earningsConfigList.forEach((item) => {
            const fIndex = bfArray.findIndex((itemc) => itemc.calculateType === item.calculateType);
            if (fIndex > -1) {
              this.fromSettinInfo.earningsConfig.earningsConfigList.splice(fIndex, 1, item);
            }
          });
        }
        // 订单推送设置
        if (runPushConfigList && runPushConfigList.length) {
          this.fromSettinInfo.runErrandsConfig.pushConfigList = runPushConfigList;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 点击修改
    edit(type) {
      this.settingType = type;
      this.next = this.rootAgentType ? 1 : 2;
      this.setShowStatus(false, true, false);
    },
    setShowStatus(cre = false, edit = false, look = false) {
      this.isCreated = cre;
      this.isEdit = edit;
      this.isLook = look;
    },
    saveSuccess() {
      this.next = null;
      if (!this.rootAgentType) {
        this.getList();
      } else {
        this.getList('reset');
      }
    },
    // 设置时间选择项
    datePickerData() {
      const arr = [];
      for (let i = 0; i < 24; i += 1) {
        const json = {
          label: i < 10 ? `0${i}` : i,
          value: i * 3600,
          children: [],
        };
        for (let k = 0; k < 60; k += 1) {
          json.children.push({
            label: k < 10 ? `0${k}` : k,
            value: k * 60,
          });
        }
        arr.push(json);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.level1Titel {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
}
.isRequired {
  color: #f63d2a;
}
.setting {
  height: 100%;
  .set-header {
    height: 116px;
    background: #fff;
    margin-bottom: 20px;
  }
  .settingBox {
    width: 100%;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
  }
  .look {
    position: relative;
    background: #fff;
    padding: 10px;
  }
  .fixed-btn {
    position: absolute;
    top: 42px;
    right: 30px;
  }
}
.showDetailInfo p,
.tabelDetailInfo p {
  margin-top: 10px;
  color: #666;
  span {
    display: inline-block;
  }
}
.showDetailInfo {
  span:not(.langLabel):not(.center-width) {
    width: 100px;
    margin: 0 10px;
    text-align: center;
    font-weight: bold;
    color: #444;
  }
  .center-width {
    width: 160px;
  }
  .langLabel {
    width: 400px;
  }
}
.tabelDetailInfo span {
  width: 180px;
  font-weight: bold;
  color: #444;
}
.tabelDetailInfo div p:not(:nth-of-type(1)) span {
  font-weight: 200;
}
.noset {
  color: #666;
  box-sizing: border-box;
  padding-left: 30px;
}
</style>
