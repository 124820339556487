
<template>
  <div class="setting distribution" v-loading="loading" element-loading-text="数据获取中..." element-loading-customClass='k-ele-loading'>
    <notCreated @setting="edit('add')" v-if="isCreated">
      <div slot="noCreat">未进行外卖配送业务设置</div>
    </notCreated>
    <!-- 查看 -->
    <div class="look" v-if="isLook">
      <div class="isRequired">注：所有设置只是针对您自建的配送团队骑手有效，其他代理商或第三方其他配送骑手，将不会执行以下设置，将根据其他代理商设置或第三方配送规则执行。</div>
      <el-button v-if="isLook" type='primary' class="fixed-btn" @click="edit('edit')">修改设置</el-button>
      <p class="level1Titel">外卖配送设置</p>
      <infoListBorder class="showDetailInfo" title="配送计费设置" :required="1">
        <div v-if="getWMArrayData('feeDims','Distance').length">
          <p v-for="(itemc,indexc) in getWMArrayData('feeDims','Distance')" :key="indexc">
            起<span>{{itemc.feeCondk[0]}}km</span>
            至<span>{{itemc.feeCondk[1]}}km</span>
            配送费<span>{{itemc.feePrice}}元</span>
            整段配送时长<span>{{itemc.feeMinutes}}分钟</span>
            <span class="label" v-if="getSYArrayData('OpenRelay').openConfig">1段配送时长</span>
            <span v-if="getSYArrayData('OpenRelay').openConfig">
              {{itemc.extendData ? itemc.extendData.relayRiderDistributionTimes[0].minute : ''}}分钟
            </span>
          </p>
        </div>
        <p v-else>暂未配置</p>
      </infoListBorder>
      <infoListBorder class="tabelDetailInfo" title="高峰/夜间特殊时间段加价设置" :required="0">
        <div v-if="getWMArrayData('feeDims','Peak').length">
          <p><span>开始时间</span><span>结束时间</span><span>加价金额</span></p>
          <p v-for="(itemc,indexc) in getWMArrayData('feeDims','Peak')" :key="indexc">
            <span>{{formattingTime(itemc.feeCondk[0][0]+itemc.feeCondk[0][1])}}</span>
            <span>{{formattingTime(itemc.feeCondk[1][0]+itemc.feeCondk[1][1])}}</span>
            <span>{{itemc.feePrice}}元</span>
          </p>
        </div>
        <p v-else>暂未配置</p>
      </infoListBorder>
      <infoListBorder class="tabelDetailInfo" title="节假日特殊日期加价设置" :required="0">
        <div v-if="getWMArrayData('feeDims','Holiday').length">
          <p><span>开始时间</span><span>结束时间</span><span>加价金额</span></p>
          <p v-for="(itemc,indexc) in getWMArrayData('feeDims','Holiday')" :key="indexc">
            <span>{{formattingSecondToDate(itemc.feeCondk[0])}}</span>
            <span>{{formattingSecondToDate(itemc.feeCondk[1])}}</span>
            <span>{{itemc.feePrice}}元</span>
          </p>
        </div>
        <p v-else>暂未配置</p>
      </infoListBorder>
      <!-- 转单设置 -->
      <infoListBorder title="转单设置" :required="2">
        开启接力送
        <el-radio-group class="changeOpenconfig" v-model="getSYArrayData('OpenRelay').openConfig" disabled>
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </infoListBorder>
      <!-- 骑手收益设置 -->
      <p class="level1Titel">骑手收益设置<span>（必填）</span></p>
      <p v-if="!fromSettinInfo.earningsConfig.earningsConfigList.length" class="noset">暂未配置</p>
      <!-- <template v-for="(item,index) in fromSettinInfo.earningsConfig.earningsConfigList"> -->
      <infoListBorder class="showDetailInfo" title="按比例计算骑手收益" scendTitle="根据每单总配送费设置骑手收益比例">
        <p v-if="!getSYArrayData('ScaleIncome')[0].earningsScale">暂未配置</p>
        <p v-else>每单骑手从配送费当中抽取<span>{{getSYArrayData('ScaleIncome')[0].earningsScale}}%</span>抽佣比例不能大于100%</p>
      </infoListBorder>
      <!-- 接力骑手收益设置 -->
      <infoListBorder class="showDetailInfo" title="接力骑手收益设置" :required="1" v-if="getSYArrayData('OpenRelay').openConfig===1">
        <p>按比例计算骑手收益<i class="colorRed">(根据每单总配送费设置骑手收益比例,抽佣比例合计不能大于100%)</i></p>
        <p v-for="(itemc,indexc) in getSYArrayData('RelayRiderIncome')" :key="indexc">
          {{itemc.scope}}级骑手从配送费当中抽取<span>{{itemc.earningsScale}}</span>%</p>
      </infoListBorder>
      <infoListBorder class="showDetailInfo" title="额外奖励骑手配送费" scendTitle="奖励为累计添加，从代理商账户余额扣除，接力订单无奖励">
        <div v-if="getSYArrayData('AwardIncome').length">
          <p v-for="(itemc,indexc) in getSYArrayData('AwardIncome')" :key="indexc">
            每日完成配送<span>{{itemc.scope}}单</span>奖励<span>{{itemc.earningsPrice}}元</span>
          </p>
        </div>
        <p v-else>暂未配置</p>
      </infoListBorder>
      <infoListBorder class="showDetailInfo" title="骑手罚款机制" scendTitle="骑手每天最多允许放弃3单" :required="1">
        <p v-for="(itemc,indexc) in getSYArrayData('CancelPunitive')" :key="indexc">
          <span class="langLabel" v-if="indexc<=2">当日第{{tiems[itemc.scope]}}次放单,扣除当前放单订单收益金额的</span>
          <span class="langLabel" v-else>超时配送,扣除当前超时订单收益金额的</span>
          <span v-if="itemc.earningsScale==null || itemc.earningsScale===''">暂未配置</span>
          <span v-else>{{itemc.earningsScale}}%</span>
        </p>
      </infoListBorder>
      <infoListBorder class="showDetailNowidth" title="订单优先展示给代理配送团队" :required="1">
        <p v-if="!getWMArrayData('pushDims','ThirdSeconds')">暂未配置</p>
        <p v-else><span>{{getWMArrayData('pushDims','ThirdSeconds')}}分钟</span>无代理团队骑手接单，则订单发往第三方配送平台</p>
      </infoListBorder>
      <infoListBorder class="showDetailNowidth" title="优先全职骑手抢单设置" :required="0">
        <p v-if="!getWMArrayData('pushDims','Minutes')">暂未配置</p>
        <p v-else><span>{{getWMArrayData('pushDims','Minutes')}}分钟</span>无全职骑手接单，兼职骑手显示并可抢单</p>
      </infoListBorder>
      <infoListBorder class="showDetailInfo" title="骑手操作到店/送达设置" :required="0">
        <p v-if="!getWMArrayData('pushDims','Shop')&&!getWMArrayData('pushDims','User')">暂未配置</p>
        <p v-if="getWMArrayData('pushDims','Shop')">距离商户<span>{{getWMArrayData('pushDims','Shop')}}米</span>可操作“到店”按钮</p>
        <p v-if="getWMArrayData('pushDims','User')">距离用户<span>{{getWMArrayData('pushDims','User')}}米</span>可操作“已送达”按钮</p>
      </infoListBorder>
      <infoListBorder title="你是否需要用自己的骑手,去配送第三方订单(美团等)?" :required="1">
        <el-radio-group class="thirdParty-radio" v-model="thirdPartyOrder" v-if="thirdPartyOrder!=null" disabled>
          <el-radio :label="1">已有骑手开始配送美团订单</el-radio>
          <el-radio :label="0" style="margin-top:10px">暂时无骑手接美团配送订单</el-radio>
        </el-radio-group>
        <p v-else>暂未配置</p>
      </infoListBorder>
    </div>
    <!-- 修改 -->
    <div v-if="isEdit">
      <div class="set-header">
        <steps :next="next" :imgLists="imgLists" />
      </div>
      <div class='settingBox'>
        <distribution ref="distribution" v-show="next === 1" />
        <takeAwaySetting ref="takeAwaySetting" v-if='next === 2' />
        <pushTakeout ref="pushSetting" v-show="next === 3" />
      </div>
    </div>
    <setRider source="setting" />
  </div>
</template>

<script>
import setRider from '@/components/setRider/setRider.vue';
import { $sg_psConfigGet, $sg_selectMeituanSetting } from '@/api/setting';
import {
  getSeconds, parseSeconds,
  formatDate, deepClone,
} from '@base/utils';

import notCreated from './components/setting/notCreated.vue';
import steps from './components/setting/steps.vue';
import distribution from './components/setting/distribution.vue';
import takeAwaySetting from './components/setting/takeAwaySetting.vue';
import infoListBorder from './components/setting/infoListBorder.vue';
import pushTakeout from './components/setting/pushTakeout.vue';


export default {
  components: {
    notCreated,
    steps,
    distribution,
    takeAwaySetting,
    setRider,
    infoListBorder,
    pushTakeout,
  },
  data() {
    return {
      loading: false,
      isCreated: false, // 暂无设置信息
      isEdit: false, // 编辑
      isLook: false, // 查看
      settingType: null, // 设置类型 add 新增 edit 编辑
      next: null, // 设置进度
      imgLists: [
        { title: '计费设置', activeImg: '/img/setting/charging.png', defaultImg: '/img/setting/charging.png' },
        { title: '骑手收益设置', activeImg: '/img/setting/activeTakeAway.png', defaultImg: '/img/setting/takeAway.png' },
        { title: '订单推送设置', activeImg: '/img/setting/activePush.png', defaultImg: '/img/setting/push.png' },
      ],
      fromSettinInfo: { // 所有的信息
        feeDimsTypeConfig: { // 外卖配送设置
          feeDims: [
            {
              dimFrom: 'Distance',
              feeDimConds: [], //  后台格式{feeCond:'(0~1]',feePrice: '2',feeMinutes: 3,},
            },
            {
              dimFrom: 'Peak',
              feeDimConds: [], //  {feeCond: '(01:01~02:01]',feePrice: '3',},
            },
            {
              dimFrom: 'Holiday',
              feeDimConds: [], // {feeCond: '[1619798400~1620368110]',feePrice: '1',},
            },
          ], // 外卖计费设置
          pushDims: [
            {
              dimFrom: 'ThirdSeconds', //  订单优先展示给代理配送团队(必填)
              feeDimConds: [
                {
                  pushNumbf: null,
                  pushNum: null,
                },
              ],
            },
            {
              dimFrom: 'Minutes', // 优先全职骑手抢单设置
              feeDimConds: [
                {
                  pushNumbf: null,
                  pushNum: null,
                },
              ],
            },
            {
              dimFrom: 'Shop', // 可操作“到店”按钮
              feeDimConds: [
                {
                  pushNum: null,
                },
              ],
            },
            {
              dimFrom: 'User', // 可操作“已送达”按钮
              feeDimConds: [
                {
                  pushNum: null,
                },
              ],
            },
          ], // 订单推送设置
        },
        earningsConfig: { // 骑手收益设置
          earningsConfigList: [
            {
              calculateType: 'ScaleIncome', // 按比例计算骑手收益
              openConfig: 1,
              configCondList: [
                {
                  earningsScale: null,
                  serial: 1,
                },
              ],
            },
            {
              calculateType: 'OpenRelay', // 转单设置
              openConfig: 0,
            },
            {
              calculateType: 'RelayRiderIncome', // 接力骑手收益设置
              openConfig: 1,
              configCondList: [
                {
                  earningsScale: '',
                },
              ],
            },
            {
              calculateType: 'AwardIncome', // 奖励
              openConfig: 1,
              configCondList: [], // {serial: 1, // 序号 scope: null, // 单earningsPrice: null, // 元 }
            },
            {
              calculateType: 'CancelPunitive', // 惩罚
              openConfig: 1,
              configCondList: [ // {serial: 1, // 序号 scope: 1, // 第几次放单 earningsScale: null, // 元  ruleType: 'CancelOrder', // 单}
                {
                  earningsScale: null,
                  serial: 1,
                  scope: 1,
                  ruleType: 'CancelOrder',
                },
                {
                  earningsScale: null,
                  serial: 2,
                  scope: 2,
                  ruleType: 'CancelOrder',
                },
                {
                  earningsScale: null,
                  serial: 3,
                  scope: 3,
                  ruleType: 'CancelOrder',
                },
                {
                  earningsScale: null,
                  serial: 4,
                  ruleType: 'DistributionMaxTime',
                },
              ],
            },
          ],
        },
        thirdPartyOrder: null,
        businessType: 1,
      },
      thirdPartyOrder: null, // 第三方订单设置状态 null 未设置 0 暂无 1 已有
      tiems: ['', '一', '二', '三'],
    };
  },
  mounted() {
    this.getList();
    $sg_selectMeituanSetting().then((d) => {
      const val = !d || !d.state ? null : Number(d.val);
      this.thirdPartyOrder = val;
      this.fromSettinInfo.thirdPartyOrder = val;
    });
  },
  methods: {
    // 获取外卖配送设置数据
    getWMArrayData(source, type) {
      const dataArray = this.fromSettinInfo.feeDimsTypeConfig[source];
      const itemD = dataArray.find((item) => item.dimFrom === type);
      if (source === 'pushDims') {
        if (itemD && itemD.feeDimConds[0] && itemD.feeDimConds[0].pushNum != null && itemD.feeDimConds[0].pushNum !== '') {
          return itemD.feeDimConds[0].pushNumbf ? itemD.feeDimConds[0].pushNumbf : itemD.feeDimConds[0].pushNum;
        } else {
          return null;
        }
      } else {
        return itemD && itemD.feeDimConds.length ? itemD.feeDimConds : [];
      }
    },
    // 获取骑手收益设置数据
    getSYArrayData(type) {
      const dataArray = this.fromSettinInfo.earningsConfig.earningsConfigList;
      const itemD = dataArray.find((item) => item.calculateType === type);
      if (type === 'OpenRelay') {
        return itemD;
      } else {
        return itemD && itemD.configCondList.length ? itemD.configCondList : [];
      }
    },
    // 秒转日期
    formattingSecondToDate(seconds) {
      return formatDate(seconds / 1000, 'yyyy-MM-dd HH:mm:ss');
    },
    // 秒数转时分
    formattingTime(seconds) {
      return parseSeconds(seconds);
    },
    // 设置即将设置的环节 （1 计费设置 2 骑手收益设置 3 订单推送设置）
    setNextPage(page) {
      this.next = page;
    },
    getList(getInfoType = null) {
      if (getInfoType) {
        this.fromSettinInfo = this.$options.data().fromSettinInfo;
      }
      this.loading = true;
      $sg_psConfigGet({ businessType: 1 }).then((res) => {
        const {
          feeDimsTypeConfig: { feeDims, pushDims },
          earningsConfig: { earningsConfigList },
        } = res;
        // 外卖计费设置
        if (feeDims.length) {
          this.setShowStatus(false, false, true);
          for (let i = 0; i < feeDims.length; i += 1) {
            const childItem = feeDims[i].feeDimConds;
            const child = feeDims[i];
            if (childItem && childItem.length) {
              for (let j = 0; j < childItem.length; j += 1) {
                const childItemInfo = childItem[j];
                if (child.dimFrom === 'Distance') {
                  const feecond = childItemInfo.feeCond[0] === '[' ? childItemInfo.feeCond.match(/\[(\S*)]/) : childItemInfo.feeCond.match(/\((\S*)]/);
                  childItemInfo.feeCondk = feecond[1].split('~');
                  childItemInfo.extendData = childItemInfo.extendData || { relayRiderDistributionTimes: [{ level: 1, minute: '' }] };
                }
                if (child.dimFrom === 'Peak') {
                  const feecond = childItemInfo.feeCond[0] === '[' ? childItemInfo.feeCond.match(/\[(\S*)]/) : childItemInfo.feeCond.match(/\((\S*)]/);
                  const timeArray = feecond[1].split('~');
                  const startMinute = getSeconds(timeArray[0]);
                  const startSeconds = startMinute % 3600;
                  const endMinute = getSeconds(timeArray[1]);
                  const endSeconds = endMinute % 3600;
                  childItemInfo.feeCondk = [[startMinute - startSeconds, startSeconds], [endMinute - endSeconds, endSeconds]];
                }
                if (child.dimFrom === 'Holiday') {
                  const feecond = childItemInfo.feeCond.match(/\[(\S*)]/)[1];
                  childItemInfo.feeCondk = feecond.split('~').map((item) => item * 1000);
                }
              }
            }
          }
        } else {
          this.setShowStatus(true, false, false);
        }
        if (pushDims.length) {
          for (let j = 0; j < pushDims.length; j += 1) {
            const item = pushDims[j];
            if (item.dimFrom === 'ThirdSeconds' || item.dimFrom === 'Minutes') {
              if (item) {
                const timeNum = item.feeDimConds[0].pushNum;
                item.feeDimConds[0].pushNumbf = timeNum === '' ? '-' : timeNum / 60;
              }
            } else {
              break;
            }
          }
        }
        if (feeDims.length) {
          const bfArray = deepClone(this.fromSettinInfo.feeDimsTypeConfig.feeDims);
          feeDims.forEach((item) => {
            const fIndex = bfArray.findIndex((itemc) => itemc.dimFrom === item.dimFrom);
            if (fIndex > -1) {
              this.fromSettinInfo.feeDimsTypeConfig.feeDims.splice(fIndex, 1, item);
            }
          });
        }
        if (pushDims.length) {
          const bfArray = deepClone(this.fromSettinInfo.feeDimsTypeConfig.pushDims);
          pushDims.forEach((item) => {
            const fIndex = bfArray.findIndex((itemc) => itemc.dimFrom === item.dimFrom);
            if (fIndex > -1) {
              this.fromSettinInfo.feeDimsTypeConfig.pushDims.splice(fIndex, 1, item);
            }
          });
        }
        if (earningsConfigList && earningsConfigList.length) {
          const bfArray = deepClone(this.fromSettinInfo.earningsConfig.earningsConfigList);
          earningsConfigList.forEach((item) => {
            const fIndex = bfArray.findIndex((itemc) => itemc.calculateType === item.calculateType);
            if (fIndex > -1) {
              this.fromSettinInfo.earningsConfig.earningsConfigList.splice(fIndex, 1, item);
            }
          });
        }
        // this.fromSettinInfo = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 点击修改
    edit(type) {
      this.settingType = type;
      this.next = 1;
      this.setShowStatus(false, true, false);
    },
    setShowStatus(cre = false, edit = false, look = false) {
      this.isCreated = cre;
      this.isEdit = edit;
      this.isLook = look;
    },
    saveSuccess() {
      this.next = null;
      this.getList('reset');
      $sg_selectMeituanSetting().then((d) => {
        const val = !d || !d.state ? null : Number(d.val);
        this.thirdPartyOrder = val;
        this.fromSettinInfo.thirdPartyOrder = val;
      });
    },
    // 设置时间选择项
    datePickerData() {
      const arr = [];
      for (let i = 0; i < 24; i += 1) {
        const json = {
          label: i < 10 ? `0${i}` : i,
          value: i * 3600,
          children: [],
        };
        for (let k = 0; k < 60; k += 1) {
          json.children.push({
            label: k < 10 ? `0${k}` : k,
            value: k * 60,
          });
        }
        arr.push(json);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.level1Titel {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
  span {
    color: #f63d2a;
  }
}
.isRequired {
  color: #f63d2a;
}
.setting {
  height: 100%;
  .set-header {
    height: 116px;
    background: #fff;
    margin-bottom: 20px;
  }
  .settingBox {
    width: 100%;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
  }
  .look {
    position: relative;
    background: #fff;
    padding: 10px;
    padding-bottom: 50px;
  }
  .fixed-btn {
    position: absolute;
    top: 42px;
    right: 30px;
  }
}
.showDetailNowidth p,
.showDetailInfo p,
.tabelDetailInfo p {
  margin-top: 10px;
  color: #666;
  span {
    display: inline-block;
  }
}
.showDetailNowidth span {
  margin-right: 30px;
  font-weight: bold;
  color: #444;
}
.showDetailInfo {
  span:not(.langLabel):not(.center-width):not(.label) {
    width: 100px;
    margin: 0 10px;
    text-align: center;
    font-weight: bold;
    color: #444;
  }
  i {
    font-style: normal;
  }
  .center-width {
    width: 160px;
  }
  .langLabel {
    width: 400px;
  }
}
.tabelDetailInfo span {
  width: 180px;
  font-weight: bold;
  color: #444;
}
.tabelDetailInfo div p:not(:nth-of-type(1)) span {
  font-weight: 200;
}
.noset {
  color: #666;
  box-sizing: border-box;
  padding-left: 30px;
}
.colorRed {
  color: #ef3f46;
}
.inputInfo {
  width: 130px;
  margin: 0 10px;
}
.changeOpenconfig {
  margin-left: 10px;
}
/deep/.changeOpenconfig
  .el-radio__input.is-disabled.is-checked
  .el-radio__inner,
/deep/.thirdParty-radio
  .el-radio__input.is-disabled.is-checked
  .el-radio__inner {
  background: #ef3f46 !important;
  border-color: #ef3f46 !important;
  &::after {
    background-color: #fff !important;
  }
}
/deep/.changeOpenconfig .is-checked .el-radio__label,
/deep/.thirdParty-radio .is-checked .el-radio__label {
  color: #ef3f46 !important;
}
.thirdParty-radio {
  display: flex;
  flex-direction: column;
}
</style>

