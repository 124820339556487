<template>
  <div class="distribution">
    <infoListBorder title="骑手收益设置" :required="1">
      <p>按比例计算骑手收益<span class="colorRed">(根据每单总配送费设置骑手收益比例)</span></p>
      <div class="fc mt6">
        每单骑手从配送费当中抽取
        <el-input size='small' class="inputInfo" type="number" clearable
          v-model.number="earningsConfigList[0].configCondList[0].earningsScale">
        </el-input><span class="mr5"> %</span>
        抽佣比例不能大于100%
      </div>
    </infoListBorder>
    <!-- <infoListBorder title="转单设置" :required="2">
      开启接力送
      <el-radio-group class="radioGroup" v-model="getWMArrayData('OpenRelay').openConfig"
        @change="openRelayChange(getWMArrayData('OpenRelay').openConfig)">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="0">否</el-radio>
      </el-radio-group>
    </infoListBorder> -->
    <infoListBorder title="接力骑手设置" :required="1" v-show="getWMArrayData('OpenRelay').openConfig==1">
      <p>按比例计算骑手收益<span class="colorRed">(根据每单总配送费设置骑手收益比例,抽佣比例合计不能大于100%)</span></p>
      <div class="fc mt6 openRelay-item" v-for="(item,index) in getWMArrayData('RelayRiderIncome')" :key="index">
        <p><span class="riderImage" v-if="!index"><img @click="riderBindingDialog = true" src="@/statics/img/question.png" alt=""></span>
          每单{{index+1}}级骑手从配送费当中抽取</p>
        <el-input size='small' class="inputInfo" type="number" clearable v-model.number="item.earningsScale">
        </el-input><span class="mr5">%</span>
        <el-button v-if="index>=1" type='text' class="detelBtn" icon="el-icon-delete" @click="delRelay(index)">删除</el-button>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addRelay">新增接力骑手收益设置</el-button>
    </infoListBorder>
    <infoListBorder title="额外奖励骑手配送费" scendTitle="奖励为累计添加,从代理商账户余额扣除,接力订单无奖励">
      <div class="fc mt6" v-for="(item,index) in getWMArrayData('AwardIncome')" :key="index">
        每日完成配送
        <el-input size='small' class="inputInfo" type="number" clearable v-model.number="item.scope"></el-input>单，奖励
        <el-input size='small' class="inputInfo" type="number" clearable v-model.number="item.earningsPrice"></el-input>元
        <el-button type='text' class="detelBtn" icon="el-icon-delete" @click="delDataList(index)">删除</el-button>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addReward">新增奖励设置</el-button>
    </infoListBorder>
    <infoListBorder title="骑手罚款机制" scendTitle="骑手每天最多允许放弃3单" :required="1">
      <p class="fc mb10" v-for="(item,index) in getWMArrayData('CancelPunitive')" :key="index">
        <span class="fine-list" v-if="index<3">当日第{{times[item.scope]}}次放单，扣除当前放单订单收益金额的</span>
        <span class="fine-list" v-else>超时配送，扣除当前超时订单收益金额的</span>
        <el-input size='small' class="inputInfo" type="number" clearable v-model.number="item.earningsScale"></el-input> %
      </p>
    </infoListBorder>
    <div class="bottom-btn-group">
      <PrevReclick>
        <el-button size='small' @click="submitInfo(1)" type='primary' plain>返回上一页</el-button>
      </PrevReclick>
      <el-button size='small' @click="submitInfo(3)" type='primary'>下一步，设置订单推送</el-button>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="提示" class="k-dialog" :visible.sync="bindingDialog" :close-on-click-modal="false" width="480px"
      :close-on-press-escape="false" center>
      <div class="binding-content">
        <p class="bingTishi">当前转单订单正在进行中，无法关闭此设置;订单结束后在关闭!!!</p>
        <div style="text-align:center;margin-top:20px">
          <el-button type="primary" @click="confirm">已知晓</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
    <!-- 详情弹窗 -->
    <el-dialog title="接力配送骑手说明" class="k-dialog" :visible.sync="riderBindingDialog" :close-on-click-modal="false" width="560px"
      :close-on-press-escape="false" center>
      <div class="binding-content">
        <p>
          <span>一级骑手: </span>从商家接单的骑手为一级骑手
        </p>
        <p>
          <span>二级骑手: </span>从一级骑手手中接单这个骑手就是二级骑手， 享受=级骑手收益
        </p>
        <p>
          <span>三级骑手: </span>从二级骑手手中接单这个骑手就是三级骑手，享受三级骑手收益
        </p>
        <p>
          <span>四级骑手: </span>从三级骑手手中接单这个骑手就是四级骑手，享受四级骑手收益
        </p>
        <p>
          <span>N级骑手: </span>从N-1级骑手手中接单这个骑手就是N级骑手，享受N级骑手收益
        </p>
        <div style="text-align:center;margin-top:10px">
          <el-button type="primary" @click="riderBindingDialog=false">已知晓</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@base/utils';
import { $sg_agentRelayOrder } from '@/api/setting';
import infoListBorder from './infoListBorder.vue';

export default {
  components: {
    infoListBorder,
  },
  data() {
    return {
      isSubmit: false,
      times: ['', '一', '二', '三'],
      // 骑手收益
      earningsConfigList: [],
      bindingDialog: false,
      riderBindingDialog: false,
    };
  },
  created() {
    const { earningsConfigList } = this.$parent.fromSettinInfo.earningsConfig;
    this.earningsConfigList = deepClone(earningsConfigList);
  },
  methods: {
    // 新增奖励机制
    addReward() {
      const dataArr = this.getWMArrayData('AwardIncome');
      if (dataArr.length && this.validationReward()) {
        return;
      }
      dataArr.push({
        scope: null,
        earningsPrice: null,
      });
    },
    // 删除奖励机制
    delDataList(index) {
      this.$confirm('此操作将删除该条设置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.getWMArrayData('AwardIncome').splice(index, 1);
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
      });
    },
    // 验证奖励机制
    validationReward() {
      let errorInfo = '';
      const REG_NUMBER = /^([1-9][0-9]{0,3})$/;
      const REG_PRICE = /^(\d{1,4}(\.[0-9]{1})?)$|^9999$/;
      const dataArray = this.getWMArrayData('AwardIncome');
      if (dataArray.length) {
        for (let i = 0; i < dataArray.length; i += 1) {
          const item = dataArray[i];
          if (!REG_NUMBER.test(item.scope)) {
            errorInfo = '请正确输入配送单量，1~9999正整数';
            break;
          }
          if (i > 0 && Number(item.scope) <= Number(dataArray[i - 1].scope)) {
            errorInfo = '配置阶段单量需大于前一个配置阶段单量';
            break;
          }
          if (!REG_PRICE.test(item.earningsPrice)) {
            errorInfo = '请正确输入奖励金额，0~9999(可保留一位小数)';
            break;
          }
        }
      }
      if (errorInfo) {
        this.$message.error(errorInfo);
      }
      return errorInfo;
    },
    // 新增接力骑手收益设置
    addRelay() {
      const dataArr = this.getWMArrayData('RelayRiderIncome');
      const sumNum = dataArr.reduce((sum, now) => sum + now.earningsScale, 0);
      if (sumNum > 100) {
        this.$message.error('抽佣比例合计不能大于100%');
        return;
      }
      dataArr.push({
        earningsScale: '',
      });
    },
    // 删除接力骑手
    delRelay(index) {
      this.$confirm('此操作将删除该条设置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.getWMArrayData('RelayRiderIncome').splice(index, 1);
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
      });
    },
    // 提交信息 进入下一环节
    submitInfo(val) {
      if (val === 1) {
        this.$parent.setNextPage(val);
        return;
      }

      const REG_SCALE = /^(\d{1,2}(\.[0-9]{1})?)$|^100$/;
      for (let i = 0; i < this.earningsConfigList.length; i += 1) {
        const item = this.earningsConfigList[i];
        switch (item.calculateType) {
          case 'ScaleIncome':
            if (!REG_SCALE.test(item.configCondList[0].earningsScale) || +item.configCondList[0].earningsScale === 0) {
              this.$message.error('请正确输入骑手收益比例，0~100(可保留一位小数)');
              return;
            }
            break;
          case 'RelayRiderIncome':
            if (this.getWMArrayData('OpenRelay').openConfig) {
              let s = 0;
              for (let j = 0; j < item.configCondList.length; j += 1) {
                const itemc = item.configCondList[j];
                itemc.serial = j + 1;
                itemc.scope = j + 1;
                s += itemc.earningsScale;
                if (!REG_SCALE.test(itemc.earningsScale) || +itemc.earningsScale === 0) {
                  this.$message.error('请正确输入接力骑手收益比例，0~100(可保留一位小数)');
                  return;
                }
              }
              if (s > 100) {
                this.$message.error('抽佣比例合计不能大于100%');
                return;
              }
            }
            break;
          case 'AwardIncome':
            if (this.getWMArrayData('AwardIncome').length && this.validationReward()) {
              return;
            }
            for (let j = 0; j < item.configCondList.length; j += 1) {
              const itemc = item.configCondList[j];
              itemc.serial = j + 1;
            }
            break;
          case 'CancelPunitive':
            for (let j = 0; j < item.configCondList.length; j += 1) {
              const itemc = item.configCondList[j];
              if (!REG_SCALE.test(itemc.earningsScale)) {
                this.$message.error('请正确输入骑手罚款比例，0~100(可保留一位小数)');
                return;
              }
            }
            break;
          default:
            break;
        }
      }
      this.changge();
      let earnData = deepClone(this.earningsConfigList);
      earnData = earnData.filter((item) => {
        if ((item.calculateType === 'AwardIncome' || item.calculateType === 'RelayRiderIncome') && (item.configCondList && item.configCondList.length === 0)) return false;
        return true;
      });
      this.$parent.fromSettinInfo.earningsConfig.earningsConfigList = earnData;
      this.$parent.setNextPage(val);
    },
    // 接力订单开关 选择否(查询是否有接力订单正在进行)
    openRelayChange(val) {
      if (!val) {
        $sg_agentRelayOrder().then((res) => {
          this.bindingDialog = res;
        });
      }
    },
    // 已知晓确认框
    confirm() {
      this.bindingDialog = false;
      this.getWMArrayData('OpenRelay').openConfig = 1;
    },
    // 获取外卖配送数据
    getWMArrayData(source) {
      const dataItem = this.earningsConfigList.find((item) => item.calculateType === source);
      if (source === 'OpenRelay') {
        return dataItem;
      } else {
        return (dataItem && dataItem.configCondList) || [];
      }
    },
    changge() {
      const dataArr = this.earningsConfigList;
      const itemData = dataArr.find((item) => item.calculateType === 'OpenRelay');
      const riderIncome = dataArr.find((item) => item.calculateType === 'RelayRiderIncome');
      if (!itemData.openConfig) {
        riderIncome.configCondList = [];
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.detelBtn {
  margin-left: 30px;
}
.fc {
  display: flex;
  align-items: center;
}
.mb10 {
  margin-bottom: 10px;
}
.mt6 {
  margin-top: 6px;
}
.bottom-btn-group {
  max-width: 1200px;
  margin-top: 80px;
  margin-left: 30px;
}
.addTimer {
  margin-top: 20px;
}
.fine-list {
  display: inline-block;
  width: 320px;
}
.colorRed {
  color: #ef3f46;
}
.inputInfo {
  width: 130px;
  margin: 0 10px;
}
/deep/.k-dialog {
  .el-dialog {
    border-radius: 6px;
    overflow: hidden;
    background: #ececec;
  }
  .el-dialog__header {
    color: #333;
    // text-align: left;
    padding: 14px 20px 10px 20px;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    top: 16px;
  }
  .el-dialog__body {
    background: #ffffff;
    border: 1px solid #ececec;
    padding: 20px 35px 20px;
  }
  .el-dialog__footer {
    height: 20px;
    padding: 0;
  }
}
.binding-content {
  p {
    line-height: 2;
  }
  span {
    font-weight: 600;
  }
}
.openRelay-item {
  position: relative;
  box-sizing: border-box;
  padding-left: 30px;
}
.riderImage {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
}
.riderImage img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.radioGroup {
  margin-left: 10px;
}
</style>
